import { Button, Col, DatePicker, Divider, Drawer, Flex, Form, Image, Input, Modal, Row, Select, Skeleton, Table, Tag, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import React from "react";
import { compose } from "redux";
import { TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import { NumberOnly } from "../../components/validator";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
const { RangePicker } = DatePicker

const ReceiveByITNameItem = [
    {
        "value": "Ko Shine Ko Oo",
        "label": "Ko Shine Ko Oo"
    },
    {
        "value": "Ko Htet Aung Khaing",
        "label": "Ko Htet Aung Khaing"
    },
    {
        "value": "Ko Min Aung",
        "label": "Ko Min Aung"
    },
    {
        "value": "Ko Ye Htike Aung",
        "label": "Ko Ye Htike Aung"
    }
]

const TicketStatusItem = [
    {
        "value": "PENDING",
        "label": "Pending"
    },
    {
        "value": "RECEIVE_BY_IT",
        "label": "Receive By IT"
    },
    {
        "value": "AT_REPAIR_SHOP",
        "label": "At Repair Shop"
    },
    {
        "value": "RETURNED_TO_IT",
        "label": "Returned to IT"
    },
    {
        "value": "COMPLETED",
        "label": "Completed"
    }

]

const DepartmentItem = [
    {
        "value": "Management Office",
        "label": "Management Office"
    },
    {
        "value": "Admin",
        "label": "Admin"
    },
    {
        "value": "YGN Operation",
        "label": "YGN Operation"
    },
    {
        "value": "Fleet",
        "label": "Fleet"
    },
    {
        "value": "Recovery",
        "label": "Recovery"
    },
    {
        "value": "Account & Finance",
        "label": "Account & Finance"
    },
    {
        "value": "Sale & Marketing",
        "label": "Sale & Marketing"
    },
    {
        "value": "CX",
        "label": "CX"
    },
    {
        "value": "HR",
        "label": "HR"
    },
    {
        "value": "Organization Development",
        "label": "Organization Development"
    },
    {
        "value": "Intercity",
        "label": "Intercity"
    },
    {
        "value": "IT",
        "label": "IT"
    },
    {
        "value": "Warehouse",
        "label": "Warehouse"
    },
    {
        "value": "Station",
        "label": "Station"
    }
]



const TitleLabel = ({ label }) => (
    <div style={{ fontSize: 15, fontWeight: '600', color: 'gray', marginBottom: 5 }}>{label}</div>
)

const TextLabel = ({ label }) => (
    <div style={{ fontSize: 18, fontWeight: '600', marginBottom: 15 }}>{label}</div>
)

const StatusTitle = ({ title }) => (
    <div style={{ fontSize: 17, fontWeight: '600', marginBottom: 10, color: 'white', backgroundColor: Theme.colors.default, padding: '5px 0px 5px 10px' }}>{title}</div>
)

const TicketStatusColorMap = {
    'PENDING': Colors.red,
    'RECEIVE_BY_IT': Colors.darkYellow,
    'AT_REPAIR_SHOP': Colors.gray,
    'RETURNED_TO_IT': Colors.blue,
    'COMPLETED': Colors.darkGreen
}

class TicketRepairPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            nearbyTypes: [],
            filter_name: '',
            filter_type: 'ALL',
            isDataFetching: false,
            ticketId: null,
            selectedTicketStatus: null,
            showReceiveByITModal: false,
            showUpdateStatusModal: false,
            showDetailDrawer: false,
            updatingStatus: false,
            fileList1: [],
            fileList2: [],
            ticketDetailData: null,
            filterData: {}
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state
        let formattedFilterData = { ...filterData };

        formattedFilterData['date-range-picker'] = null;
        formattedFilterData.fromDate = filterData['date-range-picker'] ? formattedFilterData.fromDate.format("YYYY-MM-DD") : null;
        formattedFilterData.toDate = filterData['date-range-picker'] ? formattedFilterData.toDate.format("YYYY-MM-DD") : null;


        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            ...formattedFilterData
        }
        await ApiHandler({ url: Api.ticket_repair_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response?.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }





    handleSelect = (value) => {
        this.setState({ filter_type: value })
    }

    handlePaginationChange = (pageNumber) => {

        this.fetchData(pageNumber);
    };

    updateStatus = (id) => {
        this.setState({
            ticketId: id,
            showUpdateStatusModal: true
        }, () => { this.inputRef?.current?.resetFields() })
    }

    receiveByIt = (id) => {
        this.setState({
            ticketId: id,
            showReceiveByITModal: true
        })
    }

    receiveByItemConfirm = async (object) => {
        const { data } = this.state
        const requestData = {
            receiverITName: object.receiverITName,
            receiveDeviceCondition: object.receiverDeviceCondition
        }
        await ApiHandler({ url: Api.ticket_repair_receive, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, specificId: this.state.ticketId, requestData })
            .then(response => {
                const tempData = data?.map((item) => {
                    if (response?.data?.id === item.id) {
                        return response.data
                    }
                    return item

                })
                this.setState({
                    data: tempData,
                    showReceiveByITModal: false,
                    ticketId: null
                })
            }).catch(error => {

            }).finally(() => {
                this.setState({

                })
            })
    }

    onTicketStatusChange = (status) => {
        this.setState({
            selectedTicketStatus: status
        })
    }

    updateTicketStatus = async (object) => {
        const { data, ticketId } = this.state
        let requestData = {}
        let url = null
        let mediaType = MEDIA_TYPE.JSON
        this.setState({
            updatingStatus: true
        })
        if (object.ticketStatus === 'AT_REPAIR_SHOP') {
            requestData = {
                shopName: object.shopName,
                contactInfo: object.contactInfo,
                expectedReturnDate: object?.expectedReturnDate && dayjs(object?.expectedReturnDate).format('YYYY-MM-DD'),
                expectedRepairCost: object.expectedRepairCost
            }
            url = Api.ticket_repair_deliver_to_shop
        } else if (object.ticketStatus === 'RETURNED_TO_IT') {
            requestData = {
                receiverName: object.receiverName,
                repairCost: object.repairCost,
                deviceCondition: object.deviceCondition,
                additionalNotes: object.additionalNotes
            }
            url = Api.ticket_repair_returned_to_it
        } else if (object.ticketStatus === 'COMPLETED') {
            requestData = new FormData()
            requestData.append('receiverName', object.receiverName)
            requestData.append('employeeId', object.employeeId)
            requestData.append('department', object.department)
            if (object?.image1?.file) {
                requestData.append('image1', object.image1.file.originFileObj)
            }
            if (object?.image2?.file) {
                requestData.append('image2', object.image2.file.originFileObj)
            }
            url = Api.ticket_repair_complete
            mediaType = MEDIA_TYPE.FORM_DATA
        }
        await ApiHandler({ url, method: HTTP_METHOD.PUT, mediaType, specificId: ticketId, requestData })
            .then(response => {
                const tempData = data?.map((item) => {
                    if (response?.data?.id === item.id) {
                        return response.data
                    } else {
                        return item
                    }

                })
                this.setState({
                    data: tempData,
                    showUpdateStatusModal: false,
                    ticketId: null,
                    fileList1: [],
                    fileList2: []
                })
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    updatingStatus: false,
                    selectedTicketStatus: null,
                })
            })
    }

    handleChange1 = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList1: updateFileList
        })
    }
    handleChange2 = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.map(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList2: updateFileList
        })
    }

    handleDrawerChange = (record) => {
        this.setState({ showDetailDrawer: true, ticketDetailData: record })
    }

    handleSearchName = (event, key) => {
        const { filterData } = this.state

        if (key && key === 'date-range-picker') {
            if (event) {
                this.setState({
                    filterData: { ...filterData, 'fromDate': event[0], 'toDate': event[1], 'date-range-picker': event }
                })
            } else {
                this.setState({
                    filterData: { ...filterData, 'date-range-picker': null }
                })
            }
        } else if (key && key === 'departmentName') {
            this.setState({
                filterData: { ...filterData, 'departmentName': event }
            })
        } else if (key && key === 'ticketRepairStatus') {
            this.setState({
                filterData: { ...filterData, 'ticketRepairStatus': event }
            })
        } else {
            this.setState({
                filterData: { ...filterData, [event.target.name]: event.target.value }
            })
        }



    }
    render() {
        const { data, totalPagination, page, ticketDetailData, pageSize, showDetailDrawer, isDataFetching, showReceiveByITModal, showUpdateStatusModal, selectedTicketStatus, updatingStatus, fileList1, fileList2, filterData } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row table-row-pointer' : 'black-row table-row-pointer';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },

            {
                title: 'Ticket ID',
                key: 'ticketId',
                dataIndex: 'ticketId',
            },
            {
                title: 'Requestor',
                key: 'requestorName',
                render: (item) => (
                    <>
                        <div>{item?.requestorName}</div>
                        <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{item?.employeeId}</div>
                        <div>{item?.contactInfo}</div>
                    </>
                )
            },

            {
                title: 'Department',
                dataIndex: 'departmentName',
                key: 'departmentName',
            },
            {
                title: 'Urgency Level',
                dataIndex: 'urgencyLevel',
                key: 'urgencyLevel',
            },
            {
                title: 'Issue Date',
                render: (item) => (
                    <>{dayjs(item?.createdDate).format('YYYY-MM-DD')}</>
                )
            },
            {
                title: 'Ticket Status',
                render: (item) => (
                    <Tag color={TicketStatusColorMap[item.ticketRepairStatus]}>{item.ticketRepairStatus}</Tag>
                )
            },

            {
                title: 'Action',
                width: 100,
                render: (item) => (
                    <>
                        {
                            item.ticketRepairStatus === 'PENDING' ?
                                <Button type="primary" onClick={(e) => {
                                    e.stopPropagation()
                                    this.receiveByIt(item.id)
                                }}>Receive By IT</Button>
                                :
                                <></>
                        }
                        {
                            (item.ticketRepairStatus === 'AT_REPAIR_SHOP' || item.ticketRepairStatus === 'RETURNED_TO_IT' || item.ticketRepairStatus === 'RECEIVE_BY_IT')
                                ? <Button type="primary" onClick={(e) => {
                                    e.stopPropagation()
                                    this.updateStatus(item.id)
                                }}>Update Status</Button>
                                :
                                <></>
                        }
                    </>
                )
            },
        ]

        return (
            <>
                <Drawer
                    title="Repair Ticket Detail"
                    open={showDetailDrawer}
                    onClose={() => this.setState({ showDetailDrawer: false })}
                    extra={<Tag color={TicketStatusColorMap[ticketDetailData?.ticketRepairStatus]}>{ticketDetailData?.ticketRepairStatus}</Tag>}
                    width={600}
                >
                    <Row>
                        <Col span={24}>
                            <TitleLabel label="Requestor Name (Employee ID)" />
                            <TextLabel label={`${ticketDetailData?.requestorName} (${ticketDetailData?.employeeId})`} />
                            <TitleLabel label="Contact Info" />
                            <TextLabel label={ticketDetailData?.contactInfo} />
                            <TitleLabel label="Department" />
                            <TextLabel label={ticketDetailData?.departmentName} />
                            <TitleLabel label="Address" />
                            <TextLabel label={ticketDetailData?.address} />
                            <Divider />
                            <TitleLabel label="Device Type" />
                            <TextLabel label={ticketDetailData?.deviceType} />
                            <TitleLabel label="Asset ID" />
                            <TextLabel label={ticketDetailData?.assetId} />
                            <TitleLabel label="Urgency Level" />
                            <TextLabel label={ticketDetailData?.urgencyLevel} />
                            <TitleLabel label="Photo" />
                            <>
                                {
                                    ticketDetailData?.images.map((item) => <Image width={100} height={100} src={item} />)
                                }
                            </>
                            <br />
                            <br />
                            {
                                ticketDetailData?.receiverITName ?
                                    <>
                                        <StatusTitle title={"Receive By IT"} />
                                        <TitleLabel label="Receive By IT Name" />
                                        <TextLabel label={ticketDetailData?.receiverITName} />
                                        <TitleLabel label="Device Condition" />
                                        <TextLabel label={ticketDetailData?.receiveDeviceCondition} />
                                        <TitleLabel label="Receive Time" />
                                        <TextLabel label={dayjs(ticketDetailData?.receiveTime).format("MMM DD, YYYY hh:mm A")} />
                                        <br /><br />
                                    </>
                                    :
                                    <></>
                            }
                            {
                                ticketDetailData?.ticketRepairShop ?
                                    <>
                                        <StatusTitle title={"At Repair Shop"} />
                                        <TitleLabel label="Shop Name" />
                                        <TextLabel label={ticketDetailData?.ticketRepairShop?.shopName} />
                                        <TitleLabel label="Contact Info" />
                                        <TextLabel label={ticketDetailData?.ticketRepairShop?.contactInfo} />
                                        <TitleLabel label="Expected Return Date" />
                                        <TextLabel label={ticketDetailData?.ticketRepairShop?.expectedReturnDate} />
                                        <TitleLabel label="Expected Repair Cost" />
                                        <TextLabel label={ticketDetailData?.ticketRepairShop?.expectedRepairCost} />
                                        <TitleLabel label="Created Date" />
                                        <TextLabel label={dayjs(ticketDetailData?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} />
                                        <br /><br />
                                    </>
                                    :
                                    <></>

                            }

                            {
                                ticketDetailData?.ticketRepairReturn ?
                                    <>
                                        <StatusTitle title={"Returned To IT"} />
                                        <TitleLabel label="Receiver Name" />
                                        <TextLabel label={ticketDetailData?.ticketRepairReturn?.receiverName} />
                                        <TitleLabel label="Repair Cost" />
                                        <TextLabel label={ticketDetailData?.ticketRepairReturn?.repairCost} />
                                        <TitleLabel label="Device Condition" />
                                        <TextLabel label={ticketDetailData?.ticketRepairReturn?.deviceCondition} />
                                        <TitleLabel label="Additional Notes" />
                                        <TextLabel label={ticketDetailData?.ticketRepairReturn?.additionalNotes} />
                                        <TitleLabel label="Created Date" />
                                        <TextLabel label={dayjs(ticketDetailData?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} />
                                        <br /><br />
                                    </>
                                    :
                                    <></>

                            }
                            {
                                ticketDetailData?.ticketRepairComplete ?
                                    <>
                                        <StatusTitle title={"Completed"} />
                                        <TitleLabel label="Receiver Name" />
                                        <TextLabel label={`${ticketDetailData?.ticketRepairComplete?.receiverName} (${ticketDetailData?.ticketRepairComplete?.employeeId})`} />
                                        <TitleLabel label="Department" />
                                        <TextLabel label={ticketDetailData?.ticketRepairComplete?.department} />
                                        <TitleLabel label="Created Date" />
                                        <TextLabel label={dayjs(ticketDetailData?.ticketRepairShop?.createdDate).format("MMM DD, YYYY hh:mm A")} />

                                        {
                                            ticketDetailData?.ticketRepairComplete?.images && ticketDetailData?.ticketRepairComplete?.images?.length !== 0 ?
                                                <>
                                                    <TitleLabel label="Completetion Photos" /><br />
                                                    {
                                                        ticketDetailData?.ticketRepairComplete?.images.map((item) => <Image width={100} height={100} src={item} />)
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        <br /><br />
                                    </>
                                    :
                                    <></>

                            }


                        </Col>
                    </Row>
                </Drawer>
                <Modal
                    title="Receive by IT Confirmation"
                    open={showReceiveByITModal}
                    onOk={null}
                    onCancel={() => this.setState({ showReceiveByITModal: false, ticketId: null })}
                    okText="Confirm"
                    cancelText="Cancel"
                    footer={null}
                >
                    <Form layout="vertical" onFinish={this.receiveByItemConfirm} >
                        <Form.Item name="receiverITName" label="Receiver Name" rules={[
                            {
                                required: true,
                                message: "(*required)"
                            }
                        ]}>
                            <Select
                                size="large"
                                placeholder="Select Receiver Name"
                                className="custom-selector-gray"
                                options={ReceiveByITNameItem} />
                        </Form.Item>
                        <Form.Item name="receiveDeviceCondition" label="Device Condition" rules={[
                            {
                                required: true,
                                message: "(*required)"
                            }
                        ]}>
                            <Input placeholder="Enter device condition" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Confirm</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title="Receive by IT Confirmation"
                    open={showUpdateStatusModal}
                    onOk={null}
                    onCancel={() => this.setState({ showUpdateStatusModal: false, ticketId: null })}
                    okText="Confirm"
                    cancelText="Cancel"
                    footer={null}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        onFinish={this.updateTicketStatus}>
                        <Form.Item name="ticketStatus" label="Repair Shop Name" rules={[
                            {
                                required: true,
                                message: "(*required)"
                            }
                        ]}>

                            <Select
                                size="large"
                                placeholder="Ticket Status"
                                className="custom-selector-gray"
                                onChange={this.onTicketStatusChange}
                                options={[
                                    {
                                        value: 'AT_REPAIR_SHOP',
                                        label: 'At Repair Shop',
                                    },
                                    {
                                        value: 'RETURNED_TO_IT',
                                        label: 'Returned to IT',
                                    },
                                    {
                                        value: 'COMPLETED',
                                        label: 'Completed',
                                    }
                                ]} />
                        </Form.Item>
                        {
                            selectedTicketStatus === 'AT_REPAIR_SHOP' ?
                                <>
                                    <Form.Item name="shopName" label="Repair Shop Name" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="contactInfo" label="Repair Shop Contact Info" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="expectedReturnDate" label="Expected Return Date" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <DatePicker style={{ background: '#f1f1f1', height: 40, width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item name="expectedRepairCost" label="Expected Repair Cost" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                </>
                                :
                                <></>
                        }
                        {
                            selectedTicketStatus === 'RETURNED_TO_IT' ?
                                <>
                                    <Form.Item name="receiverName" label="Receiver Name" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Select
                                            size="large"
                                            placeholder="Select Receiver Name"
                                            className="custom-selector-gray"
                                            options={ReceiveByITNameItem} />
                                    </Form.Item>
                                    <Form.Item name="repairCost" label="Repair Cost"
                                        rules={[
                                            {
                                                required: true,
                                                message: "(*required)"
                                            },
                                            {
                                                validator: NumberOnly
                                            }
                                        ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="deviceCondition" label="Device Condition" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="additionalNotes" label="Additional Notes" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                </>
                                :
                                <></>
                        }
                        {
                            selectedTicketStatus === 'COMPLETED' ?
                                <>
                                    <Form.Item name="receiverName" label="Receiver Name" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="employeeId" label="Employee ID" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input size="large" style={{ backgroundColor: Theme.colors.card_bg_color }} />
                                    </Form.Item>
                                    <Form.Item name="department" label="Department" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Select size="large"
                                            className="custom-selector-gray" options={DepartmentItem} />
                                    </Form.Item>
                                    <Form.Item name="additionalNotes" label="Additional Notes" rules={[
                                        {
                                            required: true,
                                            message: "(*required)"
                                        }
                                    ]}>
                                        <Input.TextArea maxLength={200} size="large" style={{ backgroundColor: Theme.colors.card_bg_color, height: 80 }} />
                                    </Form.Item>
                                    <Flex justify="start">
                                        <Form.Item name="image1" label={"Photo 1"}>
                                            <Upload
                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                listType="picture-card"
                                                fileList={fileList1}
                                                action={null}
                                                showUploadList={{ showPreviewIcon: false }}
                                                onChange={this.handleChange1}
                                            >
                                                {fileList1.length >= 1 ? null :
                                                    <div>
                                                        <PlusOutlined />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Upload
                                                        </div>
                                                    </div>}
                                            </Upload>
                                        </Form.Item>
                                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                                            <Form.Item name="image2" label={"Photo 2"}>
                                                <Upload

                                                    accept={["image/png", "image/jpg", "image/jpeg"]}
                                                    listType="picture-card"
                                                    fileList={fileList2}
                                                    action={null}
                                                    showUploadList={{ showPreviewIcon: false }}
                                                    onChange={this.handleChange2}
                                                >
                                                    {fileList2.length >= 1 ? null :
                                                        <div>
                                                            <PlusOutlined />
                                                            <div
                                                                style={{
                                                                    marginTop: 8,
                                                                }}
                                                            >
                                                                Upload
                                                            </div>
                                                        </div>}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </Flex>
                                </>
                                :
                                <></>
                        }
                        <Form.Item>
                            <Button type="primary" loading={updatingStatus} htmlType="submit">Confirm</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={"Repair Ticket"} />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                            name="requestorName"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Requestor Phone Number"
                            name="contactInfo"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={this.handleSearchName}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Ticket ID"
                            name="ticketId"
                        />
                    </Col>
                    <Col span={6}>
                        <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                            value={filterData['date-range-picker']}
                            allowClear
                            onChange={(value) => this.handleSearchName(value, 'date-range-picker')}
                        />
                    </Col>
                    <Col span={6}>
                        <Select size="large"
                            style={{ width: '100%', }}
                            placeholder="Select department"
                            className="custom-selector-gray" options={DepartmentItem}
                            name="departmentName"
                            allowClear
                            onChange={(value) => this.handleSearchName(value, 'departmentName')}
                        />
                    </Col>
                    <Col span={6}>
                        <Select size="large"
                            style={{ width: '100%', }}
                            placeholder="Select Status"
                            className="custom-selector-gray" options={TicketStatusItem}
                            name="ticketRepairStatus"
                            allowClear
                            onChange={(value) => this.handleSearchName(value, 'ticketRepairStatus')} />
                    </Col>
                    <Col span={6} className="search">
                        <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col>

                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    key={"ticket-repair"}
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false

                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                    onRow={(record) => ({
                                        onClick: () => this.handleDrawerChange(record), // Add onClick event for row
                                    })}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(TicketRepairPage)