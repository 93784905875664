import { DeleteOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Card, Col, Drawer, Flex, List, Modal, Row, Skeleton } from 'antd';
import React, { Component } from 'react';
import { NJVButton } from '../../../components/core-component';
import { TitleLevel3 } from '../../../components/general-component';
import NJVTable from '../../../components/njv-table';
import { FORM_ITEM } from '../../../core/constant';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import withRouter from '../../../network/with-router';
import { Colors } from "../../../components/theme";

class ShipperDeviceControlPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            currentRow: null,
        }
    }

    fetchData = (id) => {
        this.setState({
            isDataFetching: true
        })
        ApiHandler({ url: Api.shipper_device_control, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: id })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    handleClearSession = () => {
        const { currentKey, currentRow } = this.state;

        this.setState({
            isDeleting: true
        })
        ApiHandler({ url: Api.device_clear_session, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: currentKey })
            .then(() => {
                this.setState({
                    openModal: false
                })
                this.fetchData(currentRow?.id)
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDeleting: false
                })
            })
    }

    handleClearAllSession = () => {
        const { currentRow } = this.state;

        this.setState({
            isDeletingAll: true
        })
        ApiHandler({ url: Api.device_clear_all_session, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: currentRow?.id })
            .then(() => {
                this.setState({
                    openDeleteAllModal: false
                })
                this.fetchData(currentRow?.id)
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDeletingAll: false
                })
            })
    }


    render() {
        const { isDrawerOpen, currentRow, isDataFetching, data, isDeleting, openModal, openDeleteAllModal, isDeletingAll } = this.state;

        const columns = [
            {
                title: 'Full Name',
                key: 'fullName',
                render: (shipper) => (
                    <span>{shipper.firstName} {shipper.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Business Name',
                key: 'businessName',
                render: (shipper) => (
                    <>
                        {
                            shipper.business ?
                                <span>{shipper.business.businessName}</span>
                                :
                                <></>

                        }
                    </>

                ),
            },
            {
                title: 'Township/City',
                key: 'township',
                render: (shipper) => (
                    <>
                        {
                            shipper.township ?
                                <span>{shipper.township.name}</span>
                                :
                                <></>

                        }
                    </>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                render: (shipper, record) => (
                    <Button size={'middle'} type="primary" shape="circle" style={{ paddingLeft: 10, paddingRight: 10 }} onClick={() => {
                        this.setState({
                            isDrawerOpen: true,
                            currentRow: record
                        });
                        this.fetchData(record.id)
                    }}> View </Button>
                ),
            },
        ]

        const filterColumn = [
            {
                key: 'businessName',
                name: 'Search by business name',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'phone',
                name: 'Search by phone',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'approvalStatus',
                name: 'Select User Type',
                type: FORM_ITEM.SELECT,
                selectCollection: [
                    {
                        value: 'APPROVED',
                        label: 'APPROVED'
                    },
                    {
                        value: 'PENDING',
                        label: 'PENDING',
                    }, {
                        value: 'REJECT',
                        label: 'REJECT',
                    },
                ]
            }
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.handleClearSession()}
                    onCancel={() => this.setState({ openModal: false })}
                    confirmLoading={isDeleting}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Modal
                    title="Delete All"
                    open={openDeleteAllModal}
                    onOk={() => this.handleClearAllSession()}
                    onCancel={() => this.setState({ openDeleteAllModal: false })}
                    confirmLoading={isDeletingAll}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete all?</p>
                </Modal>
                <Drawer
                    title={currentRow?.business.businessName}
                    width="30%"
                    height={500}
                    closable={false}
                    onClose={() => this.setState({ isDrawerOpen: false })}
                    open={isDrawerOpen}
                    extra={
                        <Button onClick={() => this.setState({ isDrawerOpen: false })}>Close</Button>
                    }
                >
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={data}
                                    renderItem={(item, index) => (
                                        <Card
                                            style={{
                                                marginBottom: 10,
                                                width: '100%',
                                            }}
                                        >
                                            <Row style={{ width: '100%' }}>
                                                <Col span={24}>
                                                    <Flex justify='space-between' align="center">
                                                        <Flex vertical gap={5}>
                                                            <span style={{ fontSize: 16, fontWeight: 500 }}>Device Name</span>
                                                            {item.deviceName}
                                                        </Flex>
                                                        <Button size={'middle'} type="primary" shape="circle" style={{ background: Colors.red }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    openModal: true,
                                                                    currentKey: item.cache_key
                                                                })
                                                            }
                                                        ><DeleteOutlined /></Button>
                                                    </Flex>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}
                                />
                                {
                                    data?.length > 0 && <Button block size="large" style={{ background: Colors.red }} type="primary" onClick={() =>
                                        this.setState({
                                            openDeleteAllModal: true,
                                        })
                                    }>Delete All Session</Button>
                                }
                            </>
                    }
                </Drawer>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Shipper Device Control"} />
                    </Col>
                    <Col span={24} style={{ marginTop: 20 }}>
                        <NJVTable
                            columns={columns}
                            apiUrl={Api.shipper_filter}
                            filters={filterColumn}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(ShipperDeviceControlPage)
