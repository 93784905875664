import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { AutoComplete, Badge, Col, ConfigProvider, Dropdown, Empty, Flex, Image, Layout, Menu, Modal, notification, Row } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import MenuItem from 'antd/es/menu/MenuItem';
import { getToken, onMessage } from 'firebase/messaging';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { SvgPowerOffOutlined, SvgSosFilled, SvgWalletFilled } from './components/custom-svg';
import Theme from './components/theme';
import { MENU_MAP, NINJA_MENU_MAP } from './core/constant';
import { GenerateSvgIconBaseOnName } from './core/nvm-utils';
import { messaging } from './firebase/firebase-config';
import Api from './network/api';
import { HTTP_METHOD } from './network/httpMethod';
import { MEDIA_TYPE } from './network/mediaType';
import { ApiHandler } from './network/network-manager';
import { isFCMTokenUpdated, updateCurrentRoute, updateFCMStatus } from './redux/cookie-slice';
import { updateDivisionList, updateTownShipList } from './redux/location-slice';
import { menuMap } from './redux/menu-slice';
import { fullName, roleName, roleType, sosCount } from './redux/profile-slice';
import CustomPath from './routes/custom-path';

const dropDownStyle = { width: 230, display: 'flex', justifyContent: 'start', alignItems: 'center' }

const dropDownChildBubbleStyle = {
    width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f1f1f1', borderRadius: 100, marginRight: 10, cursor: 'pointer'
}
const dropDownChildTextStyle = {
    fontSize: 15,
    fontWeight: '500'
}

const buildLink = ({ path, menuName }) => (
    <Link
        to={path}>
        <div style={{ display: 'flex', alignItems: 'center', paddingBlock: 4 }}>
            {GenerateSvgIconBaseOnName(menuName)}
            <span className="sidebar-text">{menuName}</span>
        </div>
    </Link>
)

function App() {
    const logo = require('./asset/Logo.png');
    const profile = require('./asset/profile.png');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const menuAccessMap = useSelector(menuMap)

    // const shipperPendingCount = useSelector(approvalCount);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [toNinjaPortal, setToNinjaPortal] = useState(false);
    const [count, setCount] = useState({});
    const userName = useSelector(fullName)
    const userRoleType = useSelector(roleType)
    const userRoleName = useSelector(roleName)
    const reduxSosCount = useSelector(sosCount)
    const fcmTokenStatus = useSelector(isFCMTokenUpdated)

    const [filterList, setFilterList] = useState([])

    let menuIndex = 1;

    useEffect(() => {
        let menuList = []
        Object.keys(MENU_MAP).forEach((category, index) => {
            Object.entries(MENU_MAP[category]).forEach(([key, item]) => {
                if (menuAccessMap[key] !== null && menuAccessMap[key] !== undefined) {
                    menuList.push({
                        'key': item.name,
                        'label': buildLink({ path: item.route, menuName: item.name }),
                        'value': item.name,
                    })
                }
            });
        });

        setFilterList(menuList)

    }, [menuAccessMap])



    useEffect(() => {
        dispatch(updateCurrentRoute(location.pathname))
        fetchCount()
        fetchDivision()
        document.body.style.zoom = "85%";
        requestPermission()
        const showNotification = onMessage(messaging, (payload) => {
            openNotification(payload)
        })
        return () => {
            showNotification()
        }
    }, [])

    const fetchDivision = async () => {
        await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                let township_map = {}
                let division_collection = []

                division_collection = response

                dispatch(updateDivisionList(response));

                division_collection?.forEach(division => {
                    township_map[division.id] = division.townshipDTOList
                })

                dispatch(updateTownShipList(township_map))
            })
            .catch((error) => {
            })
    }

    const requestPermission = () => { //permission for station manager
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                getToken(messaging, { vapidKey: 'BGbZuQn-ibnqU4WMbuzLO3dkbCHbmL63cFV0RjQ8dzTFE1KF5mvMECJVWYRjKPKkw6yZom8xTrVDlwNvrOCwcgo' })

                    .then((currentToken) => {
                        if (currentToken) {
                            if (!fcmTokenStatus) {
                                updateToken(currentToken)
                            }
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                    });
            } else if (permission === 'denied') {
                console.log("You denied for the notification");
            }
        })
    }

    const openNotification = (payload) => {
        notification.open({
            message: payload?.data?.title,
            description: payload?.data?.message,
            duration: 5
        });
    };

    const confirmLogout = () => {
        setLogoutModalOpen(false)
        navigate(CustomPath.logout)
    }

    // const fetchSosCount = async () => {
    //     try {
    //         const response = await ApiHandler({ url: Api.sos_count, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
    //         if (response) {
    //             dispatch(updateSos(response.count))
    //             // setTotalSosCount(response.count)
    //         }
    //     } catch (error) {
    //     }
    // }

    const updateToken = async (token) => {
        try {
            await ApiHandler({ url: Api.base_user_firebase_token, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData: { webFirebaseToken: token }, disableShowMessage: true })
            dispatch(updateFCMStatus(true))
        } catch (error) {
            console.log(error);
        }
    }

    const fetchCount = async () => {
        try {
            const response = await ApiHandler({ url: Api.shipper_count, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                setCount({
                    pendingCount: response.pendingCount,
                    approvedCount: response.settlementReadyCount
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const items = [
        {
            label: <div onClick={() => navigate(CustomPath.wallet)}
                style={dropDownStyle}
            >
                <div style={dropDownChildBubbleStyle}>
                    <SvgWalletFilled width={20} height={20} />
                </div>
                <span style={dropDownChildTextStyle}>
                    Change Wallet Passcode
                </span>
            </div>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => {
                setToNinjaPortal(!toNinjaPortal);
                navigate(CustomPath.dashboard);
            }}
                style={dropDownStyle}
            >
                <div style={dropDownChildBubbleStyle}>
                    {
                        !toNinjaPortal ? <ArrowRightOutlined /> : <ArrowLeftOutlined width={20} height={20} />
                    }
                </div>
                <span style={dropDownChildTextStyle}>
                    {
                        !toNinjaPortal ? 'Go to Ninja Portal' : 'Out from Ninja Portal'
                    }
                </span>
            </div>,
            key: '1',
        },
        // {
        //     type: 'divider',
        // },
        // {
        //     label: <div
        //         style={dropDownStyle}
        //     >
        //         <div style={dropDownChildBubbleStyle}>
        //             <SettingOutlined />
        //         </div>
        //         <span style={dropDownChildTextStyle}>
        //             Setting
        //         </span>
        //     </div>,
        //     children: [
        //         {
        //             key: '2-1',
        //             label: <span style={dropDownChildTextStyle} onClick={() => {
        //                 // dispatch(removeMenu());
        //             }
        //             }
        //             >
        //                 Refresh Menu
        //             </span>,
        //         },
        //         {
        //             key: '2-2',
        //             label: <span style={dropDownChildTextStyle} onClick={() => {
        //                 dispatch(clearEnumOptions())      
        //                 dispatch(clearLocation()); 
        //                 window.location.reload();
        //             }}>
        //                 Cache Clear
        //             </span>,
        //         },
        //     ],
        //     key: '2',
        // },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => setLogoutModalOpen(true)}
                style={dropDownStyle}
            >
                <div style={dropDownChildBubbleStyle}>
                    <SvgPowerOffOutlined width={20} height={20} />
                </div>
                <span style={dropDownChildTextStyle}>
                    Logout
                </span>
            </div>,
            key: '3',
        },

    ];


    return (
        <>
            <Modal
                title="Logout Confirmation"
                centered
                open={logoutModalOpen}
                onOk={() => confirmLogout()}
                onCancel={() => setLogoutModalOpen(false)}
                okText="Logout"
                cancelText="Cancel"
            >
                <p>Are you sure to log out?</p>
            </Modal>
            <Layout>
                <div>
                    <div
                        style={{
                            height: "70px",
                            display: 'flex',
                            alignItems: 'center',
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            background: '#ffffff',
                            zIndex: 2,
                            boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Row style={{ width: '100%', alignItems: 'center' }}>
                            <Col lg={0} md={0} sm={0} xs={0}></Col>
                            <Col lg={12} md={12} sm={18} xs={18} className='logo-center'>
                                <Flex justify='flex-start'>
                                    <Link to={CustomPath.dashboard}>
                                        <Image
                                            width={180}
                                            src={logo}
                                            preview={false}
                                        />
                                    </Link>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Select: {
                                                    selectorBg: Theme.colors.input_bg_color
                                                },
                                            },
                                        }}
                                    >
                                        <AutoComplete
                                            variant="filled"
                                            style={{ marginLeft: 30, width: 400, fontSize: 20, height: 45, borderRadius: 10 }}
                                            options={filterList}
                                            placeholder='Type and search menu'
                                            allowClear={true}
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            notFoundContent={<Empty />}
                                        />
                                    </ConfigProvider>

                                </Flex>
                            </Col>
                            <Col lg={12} md={12} sm={6} xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {
                                    reduxSosCount !== 0 ?
                                        <div
                                            style={{ marginRight: 10 }}
                                        >
                                            <Badge count={reduxSosCount} >
                                                <Link to={CustomPath.sos}>
                                                    <div
                                                        style={{ width: 36, padding: 6, height: 34, borderRadius: 10, backgroundColor: 'red', textAlign: 'center' }}
                                                    >
                                                        <SvgSosFilled width={20} height={20} color={"#fff"} />
                                                    </div>
                                                </Link>
                                            </Badge>
                                        </div>
                                        :
                                        <></>
                                }

                                <div
                                    style={{ marginRight: 15, textAlign: 'right' }}
                                >
                                    <span style={{ fontSize: 18, fontWeight: '600' }}>{userName}</span><br />
                                    <span style={{ fontSize: 15, fontWeight: '600', color: 'gray' }}>{userRoleName}</span>
                                </div>

                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                >
                                    <Image
                                        preview={false}
                                        style={{
                                            width: 45,
                                            height: 45,
                                            padding: 1,
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                            borderRadius: 30,
                                            border: '2px solid'
                                        }}
                                        src={profile} />
                                </Dropdown>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Layout>
                    <Sider
                        className='custom-side-bar'
                        width={240}
                    >
                        <Menu
                            style={{
                                backgroundColor: '#F8F8F8',
                                border: 'none'
                            }}
                            mode='inline'
                        >
                            {

                                Object.keys(toNinjaPortal ? NINJA_MENU_MAP : MENU_MAP).map((category, index) => {

                                    let existMenu = false;
                                    Object.entries(toNinjaPortal ? NINJA_MENU_MAP[category] : MENU_MAP[category]).map(([key, item]) => {
                                        if (menuAccessMap[key] !== null && menuAccessMap[key] !== undefined) {
                                            existMenu = true;
                                            return;
                                        }
                                    });

                                    if (existMenu && category !== 'Pricing Group') {
                                        return <>
                                            <div className='siber-header'>{category}</div>
                                            {
                                                Object.entries(toNinjaPortal ? NINJA_MENU_MAP[category] : MENU_MAP[category]).map(([key, item],) => {
                                                    const access = menuAccessMap[key]
                                                    if (access !== null && access !== undefined) {
                                                        menuIndex += 1;
                                                        return (
                                                            <MenuItem key={menuIndex} className='sidebar-item'>
                                                                <Link to={item.route}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(item.name)}
                                                                        <span className="sidebar-text" style={{ marginRight: item.name === 'Shipper Approval' || item.name === 'Settlement' ? 20 : 0 }}>{item.name}</span>
                                                                        {
                                                                            item.name === 'Shipper Approval' ?
                                                                                <Badge count={count?.pendingCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                                : <></>
                                                                        }
                                                                        {
                                                                            item.name === 'Settlement' ?
                                                                                <Badge count={count?.approvedCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                                : <></>
                                                                        }
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                        )
                                                    }

                                                })
                                            }
                                        </>
                                    } else if (existMenu && category === 'Pricing Group') {
                                        return <>
                                            <div className='siber-header'>Pricing Group</div>
                                            <MenuItem key={70} className='sidebar-item'>
                                                <Link
                                                    to={{
                                                        pathname: CustomPath.normal_pricing_group
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {GenerateSvgIconBaseOnName('Default Pricing')}
                                                        <span className="sidebar-text">Default Pricing</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                            <MenuItem key={71} className='sidebar-item'>
                                                <Link
                                                    to={{
                                                        pathname: CustomPath.publishing_house_pricing_group
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {GenerateSvgIconBaseOnName('Publishing House Pricing')}
                                                        <span className="sidebar-text">Publishing House Pricing</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                            <MenuItem key={72} className='sidebar-item'>
                                                <Link
                                                    to={{
                                                        pathname: CustomPath.ninja_point_pricing_group
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {GenerateSvgIconBaseOnName('Ninja Point Pricing')}
                                                        <span className="sidebar-text">Ninja Point Pricing</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                            <MenuItem key={73} className='sidebar-item'>
                                                <Link
                                                    to={{
                                                        pathname: CustomPath.karmart_pricing_group
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {GenerateSvgIconBaseOnName('Karmart Pricing')}
                                                        <span className="sidebar-text">Karmart Pricing</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                            <MenuItem key={74} className='sidebar-item'>
                                                <Link
                                                    to={{
                                                        pathname: CustomPath.safari_pricing_group
                                                    }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {GenerateSvgIconBaseOnName('Safari Pricing')}
                                                        <span className="sidebar-text">Safari Pricing</span>
                                                    </div>
                                                </Link>
                                            </MenuItem>
                                        </>
                                    }

                                })
                            }
                            {/* {
                                menuAccessList && menuAccessList.map((menuAccess, index) => {
                                    const access = MENU_MAP[menuAccess.menu]
                                    if (access && access.route && access.header) {
                                        if (index === 0) {
                                            return <>
                                                <div className='siber-header'>{access.header}</div>
                                                <MenuItem key={index} className='sidebar-item'>
                                                    <Link to={access.route}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {GenerateSvgIconBaseOnName(access.name)}
                                                            <span className="sidebar-text">{access.name}</span>
                                                        </div>
                                                    </Link>
                                                </MenuItem>
                                            </>
                                        } else {
                                            const previewMenu = menuAccessList[index - 1]
                                            const previousMenuAccess = MENU_MAP[previewMenu.menu]
                                            if (previousMenuAccess) {
                                                if (previousMenuAccess.header === access.header) {
                                                    return <MenuItem key={index} className='sidebar-item'>
                                                        <Link to={access.route}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {GenerateSvgIconBaseOnName(access.name)}
                                                                <span className="sidebar-text" style={{ marginRight: access.name === 'Shipper Approval' || access.name === 'Settlement' ? 20 : 0 }}>{access.name}</span>
                                                                {
                                                                    access.name === 'Shipper Approval' ?
                                                                        <Badge count={count?.pendingCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                        : <></>
                                                                }
                                                                {
                                                                    access.name === 'Settlement' ?
                                                                        <Badge count={count?.approvedCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </Link>
                                                    </MenuItem>
                                                } else {
                                                    if (menuAccess.menu === 'PRICING_GROUP') {
                                                        return <>
                                                            <div className='siber-header'>{access.header}</div>
                                                            <MenuItem key={70} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.normal_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Default Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={71} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.publishing_house_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Publishing House Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={72} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.ninja_point_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Ninja Point Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={73} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.karmart_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Karmart Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={74} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.safari_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Safari Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                        </>
                                                    } else {
                                                        return <>
                                                            <div className='siber-header'>{access.header}</div>
                                                            <MenuItem key={index} className='sidebar-item'>
                                                                <Link to={access.route}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">{access.name}</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                        </>
                                                    }

                                                }
                                            }
                                        }
                                    }

                                })
                            } */}
                            {
                                userRoleType === 'ADMIN' && !toNinjaPortal ?
                                    <>
                                        <div className='siber-header'>{'Admin Only'}</div>
                                        <MenuItem key={100} className='sidebar-item'>
                                            <Link to={CustomPath.update_order_status}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('Order Status Update')}
                                                    <span className="sidebar-text">Order Status Update</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem key={101} className='sidebar-item'>
                                            <Link to={"/api/v1/token-campaign"}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('Token Campaign')}
                                                    <span className="sidebar-text">Token Campaign</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem key={102} className='sidebar-item'>
                                            <Link to={CustomPath.webHosting}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('Web Hosting')}
                                                    <span className="sidebar-text">Web Hosting</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem key={103} className='sidebar-item'>
                                            <Link to={CustomPath.shipper_device_control}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('Shipper Device Control')}
                                                    <span className="sidebar-text">Shipper Device Control</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                    </>
                                    :
                                    <></>
                            }
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content className="custom-content">
                            <Row>
                                <Col span={1} />
                                <Col span={22}>
                                    <Outlet />
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
            </Layout >

        </>
    );
}

export default App;
