import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import { TitleLevel3 } from "../../../components/general-component";
import NJVTable from "../../../components/njv-table";
import Api from "../../../network/api";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { FORM_ITEM } from "../../../core/constant";

class EmployeePage extends React.Component {

    render() {

        const columns = [
            {
                title: 'Name',
                key: 'fullName',
                render: (employee) => (
                    <>
                        <div>{employee?.fullName}</div>
                        <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{employee?.employeeId}</div>
                    </>
                )
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Position',
                key: 'position',
                dataIndex: 'position'
            },
            {
                title: 'Rank',
                key: 'rank',
                dataIndex: 'rank'
            },
            {
                title: 'Department',
                key: 'department',
                render: (_, { team }) => (
                    <>{team?.department?.name}</>
                )
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (employee) => (
                    <Link to={`${CustomPath.employee_update}/${employee.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        const filterColumn = [
            {
                key: 'name',
                name: 'Search by name',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'phoneNumber',
                name: 'Search by phone',
                type: FORM_ITEM.INPUT
            },
            // {
            //     key: 'department',
            //     name: 'Select Department',
            //     type: 'DynamicSelect',
            //     enumtype: 'DEPARTMENT'
            // },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Employee"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.employee_create}><NJVAddButton /></Link>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.employee_filter}
                        filters={filterColumn}
                    />
                </Col>
            </Row>
        );
    }
}

export default compose(withRouter)(EmployeePage)