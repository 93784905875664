import { compose } from '@reduxjs/toolkit';
import { Button, Col, Row, Tag } from 'antd';
import React, { Component } from 'react';
import { formatDateTime } from '../../../components/format';
import { TitleLevel3 } from '../../../components/general-component';
import NJVTable from "../../../components/njv-table";
import Api from '../../../network/api';
import withRouter from '../../../network/with-router';
import { FORM_ITEM } from '../../../core/constant';

const colorOfLevel = (value) => {
    return value === 'NORMAL' ? '#2db7f5' : value === 'GOLD' ? '#FFD700' : value === 'PLATINUM' ? '#d9d9d9' : null;
}

class LoyaltyUsageHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDownloading: false,
            filterData: {}
        }
    }

    onDownloadStatusChange = (isDownloading) => {
        this.setState({ isDownloading });
    };

    render() {
        const { isDownloading } = this.state

        const columns = [
            {
                title: 'Business Name',
                key: 'businessName',
                dataIndex: 'businessName',
                render: (_, record) => <>{record?.userInfo?.businessName}</>
            },
            {
                title: 'Shop Name',
                key: 'shopName',
                dataIndex: 'shopName',
                render: (_, record) => <>{record?.loyaltyShop?.fullName}</>
            },
            {
                title: 'Level',
                key: 'loyaltyLevel',
                dataIndex: 'loyaltyLevel',
                align: 'center',
                render: (value) => (
                    <Tag color={colorOfLevel(value)}>{value}</Tag>
                )
            },
            {
                title: 'Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                align: 'center',
                render: (value) => formatDateTime(value)
            },
        ]

        const filterColumn = [
            {
                key: 'businessName',
                name: 'Business Name',
                type: FORM_ITEM.INPUT,
            },
            {
                key: 'shopName',
                name: 'Shop Name',
                type: FORM_ITEM.INPUT,
            },
            {
                key: 'fromDate-toDate',
                name: 'Date',
                type: FORM_ITEM.RANGEPICKER,
            },
            
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12}>
                    <TitleLevel3 label="Loyalty Usage History" />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button size="large"
                        loading={isDownloading}
                        onClick={() => {
                            this.exportExcel();
                        }}
                        type="primary" style={{ backgroundColor: 'green' }}>Export Excel</Button>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.loyalty_usage}
                        excelExport={{ url: Api.loyalty_usage_export, name: "Loyalty Usage History List" }}
                        handleExportExcel={downloadExcel => this.exportExcel = downloadExcel}
                        onDownloadStatusChange={this.onDownloadStatusChange}
                        filters={filterColumn}
                    />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(LoyaltyUsageHistory)
