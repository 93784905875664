import { createSlice } from '@reduxjs/toolkit'
import { LOC_clear_token } from '../core/custom-local-storage'

export const cookieSlice = createSlice({
    name: 'cookie',
    initialState: {
        isFCMTokenUpdated: false,
        currentRoute: '',
    },
    reducers: {
        updateCurrentRoute: (state, action) => {
            state.currentRoute = action.payload
        },
        updateFCMStatus: (state, action) => {
            state.isFCMTokenUpdated = action.payload
        },
        clearCookie: (state) => {
            state.currentRoute = ''
            state.isFCMTokenUpdated = false
            LOC_clear_token()
        }
    },
})

export const { updateCurrentRoute, clearCookie, updateFCMStatus } = cookieSlice.actions
export const currentRoute = (state) => state.cookie.currentRoute
export const isFCMTokenUpdated = (state) => state.cookie.isFCMTokenUpdated
export default cookieSlice.reducer
