import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { ApiDelete, ApiFilter } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import SearchPagination from "../../components/searchPagination";
import FetchSearchUrl from "../../components/fetch-searchUrl";
import SearchFilter from "../../components/searchFilter";

class BusGatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: true,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            searchName: ''
        }
    }

    componentDidMount() {
        const { filterData, urlPage } = FetchSearchUrl()

        const { searchName } = this.state;

        if (urlPage) {
            this.setState({
                searchName: filterData?.name || searchName,
            }, () => this.fetchData(urlPage, filterData?.name))
        }
    }

    //filter 
    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    search = () => {
        const { searchName } = this.state;
        const filterData = {
            name: searchName
        }

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1, searchName ? searchName : filterData.name)
    }

    deleteBusGate = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteBusGateConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.coverage, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    //fetch data
    fetchData = async (pageNumber, searchValue) => {
        const { page, pageSize } = this.state
        this.setState({
            isDataFetching: true,
        })
        try {
            var params = {
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1,
                name: searchValue ? searchValue : ''
            }
            const response = await ApiFilter(Api.busgate_filter, params, this.props)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {

        }
        this.setState({
            isDataFetching: false
        })

    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching, searchName } = this.state
        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Bus Gate(en)',
                dataIndex: 'busGateName',
                key: 'busGateName',
            },
            {
                title: 'Bus Gate(mm)',
                dataIndex: 'busGateNameMM',
                key: 'busGateNameMM',
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (busGate) => (
                    <Space>
                        <Link to={`${CustomPath.bus_gate_update}/${busGate.id}`} state={{ route: `${CustomPath.bus_gate_page}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteBusGate(busGate.id)}><DeleteOutlined /></Button> */}

                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteBusGateConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Bus Gate Management"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.bus_gate_set_up}><NJVAddButton /></Link>
                    </Col>
                    <Col span={6} >
                        <Input
                            value={searchName}
                            onChange={this.changeSearchName}
                            style={{ width: '100%', background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by bus gate name"
                        />
                    </Col>
                    <Col span={6}>
                        <Button size="large" onClick={() => this.search()} type="primary" >Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}
export default compose(withRouter)(BusGatePage)