import { Button, Col, Form, Row, Typography } from 'antd'
import React, { Component } from 'react'
import { NJVInput } from '../../components/core-component'
import { SvgUserPassword } from '../../components/custom-svg'
import { NumberOnlyWithLimit } from '../../components/validator'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'

export default class OwnWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasscodeLoading: false,
        }
        this.formRef = React.createRef()
    }

    handlePasscodeChange = async (value) => {
        this.setState({
            isPasscodeLoading: true
        })
        const requestData = {
            "passcode": value.newPasscode,
            "oldPasscode": value.oldPasscode
        }
        await ApiHandler({ url: Api.change_passcode, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData, customMessage: 'Changed Passcode Successfully!' })
            .then((response) => {
                this.formRef.current.resetFields()
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isPasscodeLoading: false
                })
            })
    }
    render() {
        const { isPasscodeLoading } = this.state;

        return (
            <Row style={{marginTop: 200}}>
                <Col span={8}></Col>
                <Col span={8}>
                    <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 25 }}>Change Wallet Passcode</Typography.Title>
                    <Form
                        onFinish={this.handlePasscodeChange}
                        ref={this.formRef}
                    >
                        <Form.Item
                            name="oldPasscode"
                            rules={[{ validator: (_, value) => NumberOnlyWithLimit(value, 6) }]}
                        >
                            <NJVInput
                                variant="filled"
                                prefix={<SvgUserPassword width={20} height={20} />}
                                placeholder="Old Passcode"
                                ispasswordinput={true}
                            />
                        </Form.Item>
                        <Form.Item
                            name="newPasscode"
                            rules={[{ validator: (_, value) => NumberOnlyWithLimit(value, 6) }]}
                        >
                            <NJVInput
                                variant="filled"
                                ispasswordinput="true"
                                prefix={<SvgUserPassword width={20} height={20} color={"#898989"} />}
                                placeholder="New Passcode"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button size='large' block loading={isPasscodeLoading} type="primary" htmlType="submit">
                                Confirm
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        )
    }
}
