import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Flex, message, Progress, Row, Upload } from 'antd';
import React from 'react';

class WebHostingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            progress: {}
        }
    }

    handleChange = (info) => {
        const updatedFiles = info.fileList.map(file => {
            const folderPath = file.originFileObj.webkitRelativePath;

            return {
                uid: file.uid,
                name: folderPath,
            }
        })
        this.setState({ files: updatedFiles });
    }

    handleDeleteFile = (file) => {
        const updatedFiles = this.state.files.filter(f => f.name !== file.name);

        this.setState({ files: updatedFiles });
    }

    uploadFiles = async () => {
        const { files } = this.state
        if (!files.length) {
            message.error("No files to upload")
            return
        }

        this.setState({ uploading: true });

        files.forEach((file) => {
            this.setState((prev) => ({
                progress: {
                    ...prev.progress,
                    [file.uid]: 0,
                },
            }));
        })

        for (const file of files) {
            await this.uploadFile(file);
        }

        this.setState({ uploading: false });
        message.success("Files uploaded successfully")
    }

    uploadFile = (file) => {
        return new Promise((resolve) => {
            const formData = new FormData();
            formData.append("file", file);

            const fakeUpload = () => {
                let percent = 0;

                const interval = setInterval(() => {
                    percent += 10;

                    this.setState((prev) => ({
                        progress: {
                            ...prev.progress,
                            [file.uid]: percent,
                        },
                    }));

                    if (percent >= 100) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 200);
            };

            fakeUpload();
        });
    };

    render() {
        const { files, uploading, progress } = this.state;

        return (
            <Row>
                <Col span={24}>
                    <Flex gap={10}>
                        <Upload
                            onChange={this.handleChange}
                            directory
                            beforeUpload={() => false}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined />}>Upload Directory</Button>
                        </Upload>
                        <Button type='primary' onClick={this.uploadFiles} disabled={!files.length} loading={uploading}>Deploy</Button>
                    </Flex>
                </Col>
                <Col span={24}>
                    {
                        files.map(file => (
                            <div key={file.uid} className='upload-directory'>
                                {
                                    file.name &&
                                    <Flex justify='space-between'>
                                        <span>{file.name}</span>
                                        <Flex>
                                            {
                                                uploading && (
                                                    <Progress percent={progress[file.uid] || 0}
                                                        size="small" style={{ marginRight: 10, width: 150 }} />
                                                )
                                            }
                                            {
                                                !uploading && (
                                                    <Button type="text" size='small' icon={<DeleteOutlined />} onClick={() => this.handleDeleteFile(file)} />
                                                )
                                            }
                                        </Flex>
                                    </Flex>
                                }
                            </div>
                        )
                        )
                    }
                </Col>
            </Row>
        )
    }
}

export default WebHostingPage