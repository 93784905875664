import { compose } from '@reduxjs/toolkit'
import { Col, Row, Tag } from 'antd'
import React, { Component } from 'react'
import { formatDateTime } from '../../components/format'
import { TitleLevel3 } from '../../components/general-component'
import NJVTable from '../../components/njv-table'
import Api from '../../network/api'
import withRouter from '../../network/with-router'
import { FORM_ITEM } from '../../core/constant'

export const colorOfType = (value) => {
  return value === 'TRANSFER' ? '#007bff' : value === 'DEPOSIT' ? '#28a745' : value === 'WITHDRAWAL' ? '#dc3545' : null;
}

const transaction_collection = [
  {
    value: 'DEPOSIT', label: 'Deposit',
  },
  {
    value: 'WITHDRAWAL', label: 'Withdrawl'
  },
  {
    value: 'TRANSFER', label: 'Transfer'
  }
]

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {}
    }
  }

  render() {

    const columns = [
      {
        title: 'From',
        key: 'from',
        render: (_, transaction) => (
          <>
            {
              transaction?.senderDTO?.fullName && <><span style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>{transaction.senderDTO.fullName}</span> <br /></>
            }
            {
              transaction?.senderDTO?.userType && <Tag color='#808080'>{transaction.senderDTO.userType}</Tag>
            }
          </>
        )
      },
      {
        title: 'To',
        key: 'to',
        render: (transaction) => (
          <>
            {
              transaction?.receiverDTO?.fullName && <><span style={{ fontSize: 14, fontWeight: '500', color: 'gray' }}>{transaction.receiverDTO.fullName}</span> <br /></>
            }
            {
              transaction?.receiverDTO?.userType && <Tag color='#808080'>{transaction.receiverDTO.userType}</Tag>
            }
          </>
        )
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        align: 'center',
        width: 100
      },
      {
        title: 'Transaction Type',
        key: 'walletTransactionType',
        dataIndex: 'walletTransactionType',
        align: 'center',
        width: 250,
        render: (type) => <Tag color={colorOfType(type)}>{type}</Tag>
      },
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'createdDate',
        render: (value) => formatDateTime(value)
      }
    ]

    const filterColumn = [
      {
        key: 'senderName',
        name: 'Sender Name',
        type: FORM_ITEM.INPUT
      },
      {
        key: 'walletTransactionType',
        name: 'Select Transaction Type',
        type: FORM_ITEM.SELECT,
        selectCollection: transaction_collection
      },
      {
        key: 'fromDate-toDate',
        name: 'Date',
        type: FORM_ITEM.RANGEPICKER
      },
    ]

    return (
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={"Wallet Transaction History"} />
        </Col>
        <Col span={24}>
          <NJVTable
            columns={columns}
            handleRefetch={fetchData => this.reFetch = fetchData}
            filters={filterColumn}
            apiUrl={Api.wallet_transactions}
          />
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(TransactionHistory)