import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Radio, Row, Select } from 'antd'
import { Editor } from 'primereact/editor'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NJVInput, NJVSelect } from '../../components/core-component'
import Theme from '../../components/theme'
import { NumberOnlyValidator } from '../../components/validator'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import { DEPARTMENT_COLLECTION } from '../../core/constant'

const NOGOTIABLE = "Negotiable"
const convertCurrencyStringToNumber = (currencyString) => {
    const numericString = currencyString.replace(/[^0-9,]/g, "");
    const numberString = numericString.replace(/,/g, "");
    return Number(numberString);
};

class JobPostUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNegotiable: false,
            loading: false
        };
        this.updateRef = React.createRef()
    }

    componentDidMount() {
        this.findJobPost(this.props.params.id)
        this.fetchDivision()
    }

    findJobPost = (id) => {
        ApiHandler({ url: Api.job_post, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: id })
            .then((response) => {
                if (response?.salary && response?.salary?.includes("MMK")) {
                    response['salary'] = convertCurrencyStringToNumber(response?.salary) + ""
                } else if (response?.salary && response?.salary?.includes(NOGOTIABLE)) {
                    response['salary'] = null
                    response['negotiable'] = true
                    this.setState({ isNegotiable: true })
                }
                
                this?.updateRef?.current?.setFieldsValue({...response})

            }).catch((e) => {
                console.log(e)
            }).finally(() => {
            })
    }

    fetchDivision = async (currentTownshipId) => {
        try {
            this.setState({
                isFetchingDivision: true
            })
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let townShipList = [];
                response.map(data => {
                    data?.townshipDTOList.map(township => {
                        townShipList.push({ ...township, division: { id: data.id, name: data.name } });
                    })
                })
                this.setState({
                    isFetchingDivision: false,
                    townShipList
                })
            }
        } catch (error) {
        }
    }

    updateJobForm = (formData) => {
        
        let salary = parseInt(formData?.salary);
        let salaryWithMMK;

        if (this.state.isNegotiable) {
            salaryWithMMK = NOGOTIABLE
        } else {
            const formattedSalary = salary.toLocaleString();
            salaryWithMMK = `${formattedSalary} MMK`;
        }
        formData['salary'] = salaryWithMMK
        formData['townshipId'] = this.state.selectedTownship

        this.setState({
            loading: true
        })

        ApiHandler({ url: Api.job_post, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData: formData, specificId: this.props.params.id })
            .then(() => {
                this.props.navigate(CustomPath.job_post)
            }).catch(() => {

            }).finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        const { isNegotiable, loading, isFetchingDivision, townShipList } = this.state;

        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.job_post}>Ninja Career</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Job Post Update"}
                    extra={<Button type="primary" onClick={() => this.props.navigate(CustomPath.job_post)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        onFinish={this.updateJobForm}
                        ref={this.updateRef}
                    >
                        <Row gutter={[24, 16]}>
                            <Col span={12}>
                                <Form.Item name="position" label="Position Name" rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="location" label="Location" rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVSelect
                                        showSearch
                                        style={{
                                            width: '100%',
                                        }}
                                        selectborder="#fff"
                                        loading={isFetchingDivision}
                                        placeholder="Select Location"
                                        size="large"
                                        onChange={(e, obj) => this.setState({ selectedTownship: obj.id })}
                                        allowClear
                                        optionFilterProp="label"
                                        options={
                                            townShipList && townShipList?.map((township) => {
                                                return {
                                                    label: township.division?.name,
                                                    options: [
                                                        {
                                                            value: township.name,
                                                            id: township.id,
                                                            divisionId: township.division.id,
                                                            divisionName: township.division.name,
                                                            label: township.name,
                                                            type: 'townshipId',
                                                        }
                                                    ]
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="gender" label="Gender" rules={[{ required: true, message: '*(requried)' }]}>
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Radio
                                            value="Male"
                                        >
                                            Male
                                        </Radio>
                                        <Radio
                                            value="Female"
                                        >
                                            Female
                                        </Radio>
                                        <Radio
                                            value="Open To All"
                                        >
                                            Open To All
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="salary" label="Salary" rules={[{ required: isNegotiable ? false : true, validator: isNegotiable ? null : NumberOnlyValidator }]}>
                                    <NJVInput onChange={() => this.setState({ isNegotiable: false })} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="negotiable" label=".">
                                    <Checkbox checked={isNegotiable} onChange={(e) => {
                                        this?.updateRef?.current?.setFieldsValue({ salary: null });
                                        this.setState({ isNegotiable: e.target.checked })
                                    }
                                    }>Negotiable</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="rank" label={"Rank"} >
                                    <NJVInput />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="department" label={"Department"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select options={DEPARTMENT_COLLECTION} placeholder="Select department" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="jobDescription" label="Job Description" rules={[{ required: true, message: '*(requried)' }]}>
                                    <Editor onTextChange={(e) => this.updateRef.current.setFieldsValue({ jobDescription: e.htmlValue })} style={{ background: '#fff', height: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="jobRequirements" label="Job Requirements" rules={[{ required: true, message: '*(requried)' }]}>
                                    <Editor onTextChange={(e) => this.updateRef.current.setFieldsValue({ jobRequirements: e.htmlValue })} style={{ background: '#fff', height: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="note" label="Note">
                                    <NJVInput istextareainput="true" maxLength={200} showCount style={{ height: 100 }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="statusFlag"
                                    rules={[{ required: true, message: '*(requried)' }]}>
                                    <Radio.Group>
                                        <Radio value={'ACTIVE'}>Active</Radio>
                                        <Radio value={'INACTIVE'}>Inactive</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"
                                        loading={loading}
                                    >Update</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        )
    }
}
export default compose(withRouter)(JobPostUpdate)