import { createSlice } from "@reduxjs/toolkit";

export const enumSlice = createSlice({
    name: 'enum',
    initialState: {
        enumOptionsCache: {}
    },
    reducers: {
        updateEnumOptions: (state, { payload }) => {
            state.enumOptionsCache[payload.enumType] = payload.options;
        },
        clearEnumOptions: (state) => {
            state.enumOptionsCache = {}
        }
    }
})

export const { updateEnumOptions, clearEnumOptions } = enumSlice.actions
export const enumOption = (state, enumType) =>  state.enum.enumOptionsCache || []

export default enumSlice.reducer;