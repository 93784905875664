import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { NJVSearchFilterOption } from "../../../components/core-component";


class DriverForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            roleOptions: [],
            currentDate: '',
            isLoading: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            assing_township_collection: []
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        const date = dayjs();
        this.setState({ currentDate: date.subtract(18, 'year') })
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };

    handleDivisionSelect = (value) => {
        this.setState({
            township_collection: this.props?.townshipCollectionMap[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handleAssignDivisionSelect = (value) => {
        this.setState({
            assing_township_collection: this.props?.townshipCollectionMap[value],
        })
        this.inputRef.current.setFieldsValue({ assignTownshipId: null });
    }

    saveDriver = async (values) => {
        this.setState({
            isLoading: true
        })
        try {
            if (values.primaryPhoneNumber.length > 12 || values.primaryPhoneNumber.length < 5) {
                message.destroy()
                message.error("Invalid Phone Number")
                return
            }
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }


            const response = await ApiPost(Api.driver,
                {
                    "specialCode": values.specialCode,
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth,
                    "gender": values.gender,
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "phoneNumber": values.primaryPhoneNumber,
                    "address1": values.address1,
                    "accounts": [
                        {
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    'township': {
                        'id': values.townshipId
                    },
                    'assignTownship': {
                        id: values.assignTownshipId
                    }
                }, this.props
            )

            if (response.status === 200) {
                this.props.navigate(CustomPath.driver)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        return dayjs().subtract(18, 'year')
    }




    render() {
        const { navigate } = this.props
        const { isLoading, township_collection, assing_township_collection } = this.state
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.driver}>Driver</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Driver Form"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.driver)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ gender: 'MALE', active: true }}
                        onFinish={this.saveDriver}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name="specialCode" label={"Unique Id"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter unique id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="lastName" label={"Last Name"}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="primaryPhoneNumber"
                                    label={"Phone Number"}
                                    rules={[
                                        { required: true, message: '*(requried)' }
                                    ]}>
                                    <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12} >
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        disabledDate={this.disabledDate}
                                        defaultValue={this.currentDate()}
                                        allowClear={false}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        size="large"
                                        options={
                                            this.props?.divisionCollection && this.props?.divisionCollection?.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        onChange={this.divisionSelectorChange}
                                        size="large"
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>

                                <Form.Item name="assignDivisionId" label={"Assign  Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select assign division"}
                                        onChange={this.handleAssignDivisionSelect}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        size="large"
                                        options={
                                            this.props?.divisionCollection && this.props?.divisionCollection?.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="assignTownshipId" label={"Assign Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select assign township"}
                                        onChange={this.divisionSelectorChange}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        size="large"
                                        options={
                                            assing_township_collection && assing_township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item style={{ textAlign: 'end' }}>
                            {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                            <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(DriverForm)