import {
    HistoryOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Button, Card, Col, ConfigProvider, Drawer, Flex, Form, Image, Input, List, message, Modal, Row, Skeleton, Spin, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import empty_history_image from '../../asset/shipper_history_empty.png'
import { NJVInput } from '../../components/core-component'
import { TitleLevel3 } from '../../components/general-component'
import NJVTable from '../../components/njv-table'
import Theme from '../../components/theme'
import { NumberOnlyWithExactly, NumberOnlyWithLimit } from '../../components/validator'
import { Constant, FORM_ITEM } from '../../core/constant'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { colorOfType } from './transaction-history'

const userType_collection = [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Shipper', value: 'SHIPPER' },
    { label: 'Driver', value: 'DRIVER' },
    { label: 'Employee', value: 'EMPLOYEE' }
]

class WalletsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: {},
            isOpenModal: false,
            isDrawerOpen: false,
            failedAttempts: 0,
            passcode: null,
            page: 0,
            pageSize: Constant.pageSize,
        }
        this.passcodeFormRef = React.createRef()
        this.depositFormRef = React.createRef()
    }

    componentDidMount() {
        this.checkPasscode()
    }

    fetchHistoryData = async () => {
        const { page, pageSize, currentUser } = this.state;

        this.setState({
            isHistoryLoading: true
        })

        const requestParams = {
            page,
            size: pageSize,
            userId: currentUser?.id
        }
        await ApiHandler({ url: Api.wallet_transactions, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    data: response,
                    totalPagination: response.totalElements
                })
            }
            ).catch((error) => {
            }).finally(() => {
                this.setState({
                    isHistoryLoading: false
                })
            })
    }

    loadMore = async () => {
        const { page, pageSize, currentUser, data } = this.state;

        console.log(page);


        this.setState({
            isLoadMore: true
        })
        try {
            const requestParams = {
                page: page + 1,
                size: pageSize,
                userId: currentUser?.id
            };

            const response = await ApiHandler({
                url: Api.wallet_transactions,
                method: HTTP_METHOD.GET,
                mediaType: MEDIA_TYPE.JSON,
                requestParams
            });

            const updatedData = [...data.content, ...response.content];

            this.setState((prev) => ({
                data: {
                    ...prev,
                    content: updatedData,
                    last: response.last,

                },
                totalPage: response.totalElements
            }))
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            this.setState({
                isLoadMore: false,
                page: page + 1
            })
        }
    }

    checkPasscode = async () => {
        this.setState({
            isCheckingPasscode: true
        })
        await ApiHandler({ url: Api.check_passcode, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                if (response.message === 'true') {
                    this.setState({
                        isExistPasscode: true
                    })
                } else {
                    this.setState({
                        isExistPasscode: false
                    })
                }
            })
            .catch((error) => {
            }).finally(() => {
                this.setState({
                    isCheckingPasscode: false
                })
            })
    }

    handleDeposit = async (value) => {
        this.setState({
            isDepositLoading: true
        })
        const requestData = {
            "receiverUserId": this.state.currentUser.id,
            "amount": value.amount,
            "passcode": this.state.passcode
        }
        await ApiHandler({ url: Api.deposit, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            .then((response) => {
                this.setState({
                    isOpenModal: false,
                    currentUser: null
                })
                this.reFetch()
                this.depositFormRef.current.resetFields()
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isDepositLoading: false
                })
            })
    }

    handlePasscodeSetup = async (value) => {
        this.setState({
            isPasscodeLoading: true
        })
        const requestData = {
            "passcode": value.passcode
        }
        await ApiHandler({ url: Api.setup_passcode, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true })
            .then(() => {
                this.passcodeFormRef.current.resetFields()
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isPasscodeLoading: false
                })
            })
    }

    onChange = async (text) => {
        const { failedAttempts } = this.state;
        this.setState({
            passcode: text
        })

        if (failedAttempts >= 5) {
            message.error('You have reached the maximum number of attempts.');
            return;
        }

        this.setState({
            isValidating: true
        })

        await ApiHandler({ url: Api.validate_passcode, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { passcode: text } })
            .then((response) => {

                if (response.message === 'true') {
                    this.setState({
                        isPasscodeValidated: true,
                        failedAttempts: 0
                    })
                } else {
                    this.setState((prevState) => ({
                        failedAttempts: prevState.failedAttempts + 1,
                    }));
                    message.destroy()
                    message.error('Passcode validation failed');
                    if (failedAttempts + 1 === 5) {
                        message.destroy()
                        message.error('You have reached the maximum number of attempts.');
                    }
                    return
                }
            })
            .catch((error) => { })
            .finally(() => {
                this.setState({
                    isValidating: false
                })
            })
    };

    render() {
        const { isOpenModal, isDepositLoading, currentUser, isDrawerOpen, isPasscodeValidated, isPasscodeLoading, isExistPasscode, isCheckingPasscode, data, isLoadMore, isHistoryLoading, isValidating } = this.state

        const columns = [
            {
                title: 'Name',
                key: 'fullName',
                dataIndex: 'fullName'
            },
            {
                title: 'User Type',
                key: 'userType',
                dataIndex: 'userType',
                render: (value) => <Tag color='#007bff'>{value}</Tag>
            },
            {
                title: "Wallet's Balance",
                key: 'balance',
                dataIndex: 'balance'
            },
            {
                title: 'Action',
                key: 'action',
                width: 200,
                align: 'center',
                render: (_, record) => (
                    <Flex vertical align='center'>
                        <Button size='small' type="text" style={{ fontWeight: '600', color: Theme.colors.default }} onClick={() => {
                            this.setState(
                                { isDrawerOpen: true, currentUser: record, page: 0 }
                                , () => this.fetchHistoryData())
                        }} icon={<HistoryOutlined />}>History</Button>
                        <Button size='small' type="text" style={{ marginTop: 5, fontWeight: '600', color: Theme.colors.default }} onClick={() => this.setState({ isOpenModal: true, currentUser: record })} icon={<PlusOutlined />}>Deposit</Button>
                    </Flex>
                )
            }
        ]

        const filterColumn = [
            {
                key: 'ownerName',
                name: 'Sender Name',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'employeeId',
                name: 'Employee ID',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'userType',
                name: 'Select User Type',
                type: FORM_ITEM.SELECT,
                selectCollection: userType_collection
            }
        ]

        return (
            <>
                <Modal footer={null} open={isOpenModal} onCancel={() => this.setState({ isOpenModal: false, currentUser: null })}
                    title={
                        <>
                            {currentUser?.employeeId &&
                                <><span>NinjaID : {currentUser?.employeeId}</span> <br /></>}
                            <span>{currentUser?.fullName} <Tag color='#007bff'>{currentUser?.userType}</Tag></span>
                        </>}
                >
                    <Form ref={this.depositFormRef} layout='vertical' style={{ marginTop: 25 }} onFinish={this.handleDeposit}>
                        <Form.Item name="amount" rules={[{ validator: (_, value) => NumberOnlyWithLimit(value, 6) }]}>
                            <NJVInput type='text' name="amount" placeholder="Enter Amount" size="large" style={{ background: '#f1f1f1', height: 40, marginRight: 8 }} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType='submit' loading={isDepositLoading}>Deposit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Drawer
                    width="40%"
                    height={500}
                    closable={false}
                    onClose={() => this.setState({ isDrawerOpen: false, currentUser: null })}
                    open={isDrawerOpen}
                    extra={
                        <Button onClick={() => this.setState({ isDrawerOpen: false, currentUser: null })}>Close</Button>
                    }
                    title={
                        <>
                            {currentUser?.employeeId &&
                                <><span>NinjaID : {currentUser?.employeeId}</span> <br /></>}
                            <span>{currentUser?.fullName} <Tag color='#007bff'>{currentUser?.userType}</Tag></span>
                        </>}
                >
                    {
                        isHistoryLoading ? <Skeleton />
                            :
                            <>
                                {
                                    data?.content ?
                                        <>
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={data?.content}
                                                renderItem={(item, index) => {
                                                    return (
                                                        <ConfigProvider theme={{
                                                            components: {
                                                                Card: {
                                                                    paddingLG: 10
                                                                }
                                                            }
                                                        }}>
                                                            <Card style={{ marginBottom: 10, padding: 0 }}
                                                            >
                                                                <Flex align='center' justify='space-between' flex={1}>
                                                                    <Flex gap={3}>
                                                                        <Flex vertical align='start' gap={3}>
                                                                            From:
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    fontWeight: 500,
                                                                                    marginRight: 10
                                                                                }}
                                                                            >
                                                                                {item.senderDTO.fullName}
                                                                            </span>
                                                                            <Tag color='#808080'>{item.senderDTO.userType}</Tag>
                                                                        </Flex>
                                                                        <Flex vertical align='start' gap={3}>
                                                                            To:
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    fontWeight: 500,
                                                                                    marginRight: 10
                                                                                }}
                                                                            >
                                                                                {item.receiverDTO.fullName}
                                                                            </span>
                                                                            <Tag color='#808080'>{item.receiverDTO.userType}</Tag>
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Flex vertical align='center' gap={3}>
                                                                        <span style={{
                                                                            fontSize: 15,
                                                                            fontWeight: 500,
                                                                        }}>{item.amount}</span>
                                                                        <Tag color={colorOfType(item.walletTransactionType)} >{item.walletTransactionType}</Tag>
                                                                        <span>{dayjs(item.createdDate).format('YYYY-MM-DD HH:mm A')}</span>
                                                                    </Flex>
                                                                </Flex>
                                                            </Card>
                                                        </ConfigProvider>
                                                    )
                                                }}
                                            />
                                            {
                                                !data?.last &&
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button type="primary"
                                                        onClick={() => this.loadMore()} loading={isLoadMore}
                                                    >
                                                        Load More
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Image
                                                    width={200}
                                                    preview={false}
                                                    src={empty_history_image} />
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                    No history yet!
                                                </div>
                                            </Col>
                                        </>
                                }
                            </>
                    }
                </Drawer>
                <Row gutter={[16, 16]}>
                    {
                        isCheckingPasscode ?
                            <>
                                <Col span={8}></Col>
                                <Col span={8} style={{ textAlign: 'center', marginTop: 250 }}><Spin /></Col>
                            </>
                            :
                            <>
                                {
                                    isExistPasscode ?
                                        <>
                                            {
                                                isValidating ?
                                                    <>
                                                        <Col span={8}></Col>
                                                        <Col span={8} style={{ textAlign: 'center', marginTop: 250 }}><Spin /></Col>
                                                    </> :
                                                    <>
                                                        {
                                                            isPasscodeValidated ?
                                                                <>
                                                                    <Col span={24}>
                                                                        <TitleLevel3 label={"Wallets"} />
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <NJVTable
                                                                            columns={columns}
                                                                            handleRefetch={fetchData => this.reFetch = fetchData}
                                                                            filters={filterColumn}
                                                                            defaultFilter={{ userType: 'EMPLOYEE' }}
                                                                            apiUrl={Api.wallets}
                                                                        />
                                                                    </Col>
                                                                </>
                                                                :
                                                                <>
                                                                    <Col span={8}></Col>
                                                                    <Col span={8} style={{ marginTop: 250 }}>
                                                                        <TitleLevel3 label={"Enter Wallet Passcode"} />
                                                                        <Input.OTP length={6} variant="filled" onChange={this.onChange} style={{ marginTop: 30, width: '100%' }} />
                                                                    </Col>
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Col span={8}></Col>
                                            <Col span={8}>
                                                <TitleLevel3 label={"Setup Passcode"} />
                                                <Form ref={this.passcodeFormRef} layout='vertical' style={{ marginTop: 30 }} onFinish={this.handlePasscodeSetup}>
                                                    <Form.Item name="passcode" rules={[{ validator: (_, value) => NumberOnlyWithExactly(value, 6) }]}>
                                                        <NJVInput type='text' name="passcode" placeholder="Enter Passcode" size="large" style={{ background: '#f1f1f1', height: 40, marginRight: 8 }} />
                                                    </Form.Item>
                                                    <Form.Item style={{ textAlign: 'right' }}>
                                                        <Button type="primary" htmlType='submit' loading={isPasscodeLoading}>Setup</Button>
                                                    </Form.Item>
                                                </Form>
                                            </Col>
                                        </>
                                }
                            </>
                    }
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(WalletsPage)