import { compose } from '@reduxjs/toolkit';
import { Button, Card, Checkbox, Col, Form, Image, Input, Row, message } from 'antd';
import React from "react";
import { SvgLockFilled, SvgMobileFilled } from "../../components/custom-svg";
import '../../css/login.css';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import { Authenticate, AuthenticateWithOTP } from "../auth/auth-provider";
import { LOC_storeToken } from '../../core/custom-local-storage';
const logo = require('../../asset/Logo.png');
class LoginPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: '',
            visiblePassword: false,
            isLoggin: false,
            isVerifying: false,
            isLoginSuccess: false
        }
        this.formRef = React.createRef()
    }
    login = async (values) => {
        this.setState({ isLoggin: true })
        message.destroy()
        if ((values.phoneNumber === undefined || values.phoneNumber === '') && (values.password === undefined || values.password === '')) {
            message.error("Please Enter Phone number & Password")
        } else if (values.phoneNumber === undefined || values.phoneNumber === '') {
            message.error("Please Enter Phone number")
        } else if (values.password === undefined || values.password === '') {
            message.error("Please Enter Password")
        } else if (values.phoneNumber.length > 12 || values.phoneNumber.length < 1) {
            message.error("Invalid Phone Number")
        } else {
            const isAuthenticated = await Authenticate(values.phoneNumber, values.password, this.props)
            if (isAuthenticated) {
                // this.props.navigate(CustomPath.dashboard);
                this.setState({ isLoginSuccess: true, phoneNumber: values.phoneNumber })
                // this.props.history.push(CustomPath.dashboard);
            } else {
                console.log("Authentication failed");
            }
        }
        this.setState({ isLoggin: false })
    }

    verify = async (values) => {
        const { phoneNumber } = this.state
        this.setState({ isVerifying: true })
        message.destroy()
        if (values.otp === undefined || values.password === '') {
            message.error("Please enter your otp")
        } else {
            const isAuthenticated = await AuthenticateWithOTP(phoneNumber, values.otp, this.props)
            if (isAuthenticated) {
                LOC_storeToken(isAuthenticated.data);
                this.props.navigate(CustomPath.dashboard);
            }
        }
        this.setState({ isVerifying: false })
    }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.formRef.current.setFieldsValue({ phoneNumber: numericValue })
    };

    showPassword = value => {
        this.setState({ visiblePassword: value.target.checked })
    }

    render() {
        const { visiblePassword, isLoggin, isLoginSuccess, isVerifying } = this.state
        return (
            <>
                <Row style={{ height: '100vh', display: 'flex', alignContent: 'center' }}>
                    <Col span={8} />
                    <Col span={8}>
                        <Card
                            style={{ boxShadow: 'none' }}
                            bordered={false}
                        >
                            <Row>
                                <Col span={24} style={{ marginBottom: 50 }}>
                                    <Image
                                        width={200}
                                        src={logo}
                                        preview={false}
                                    />
                                </Col>
                                {
                                    !isLoginSuccess && <Col span={24}>
                                        <Form
                                            name="normal_login"
                                            className="login-form"
                                            onFinish={this.login}
                                            ref={this.formRef}
                                        >
                                            <Form.Item
                                                style={{ marginBottom: 30 }}
                                                name="phoneNumber"
                                            >
                                                <Input
                                                    className="login-box"
                                                    style={{ backgroundColor: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                                    prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}
                                                    placeholder="Phone Number"
                                                    autoComplete="off"
                                                    onChange={this.handleInputChange}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 10 }}
                                                name="password"
                                            >
                                                <Input
                                                    className="login-box"
                                                    style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                                    prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                                                    type={visiblePassword ? "text" : "password"}
                                                    placeholder="Password"
                                                    autoComplete="off"
                                                />
                                            </Form.Item>
                                            <Checkbox
                                                style={{ marginBottom: 30 }}
                                                onChange={this.showPassword}>
                                                Show Password
                                            </Checkbox>
                                            <Row>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Form.Item>
                                                        <Button style={{ width: 150 }} type="primary" size="large" htmlType="submit" className="login-form-button" loading={isLoggin}>
                                                            Log in
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                        </Form>
                                    </Col>
                                }

                                {
                                    isLoginSuccess && <Col span={24}>
                                        <div style={{ marginBottom: 20, fontSize: 15, fontWeight: '500' }}>Enter your OTP code</div>
                                        <Form
                                            name="normal_login"
                                            className="otp-form"
                                            onFinish={this.verify}
                                        >
                                            <Form.Item
                                                style={{ marginBottom: 30 }}
                                                name="otp"
                                            >

                                                <Input.OTP size='large' style={{ width: '100%' }} variant="filled" className='otp-style' />
                                            </Form.Item>
                                            <Row>

                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Form.Item>
                                                        <Button style={{ width: 150 }} type="primary" size="large" htmlType="submit" className="login-form-button" loading={isVerifying}>
                                                            Verify
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                        </Form>
                                    </Col>
                                }

                            </Row>

                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(LoginPage)