
const FetchSearchUrl = (defaultFilter) => {
    let urlParam = new URLSearchParams(window.location.search);
    let urlPage = urlParam.get('page');

    const filter = defaultFilter || {};
    let filterData = { ...filter };

    if (urlPage) {
        const currentUrl = window.location.href;
        const index = currentUrl.indexOf('?');
        const searchParams = new URLSearchParams(currentUrl.substring(index))

        for (let [key, value] of searchParams.entries()) {
            if (key !== 'page' && value !== null && value !== undefined && value !== 'null' && value !== 'undefined') {
                filterData[key] = value;
            }
        }
    } else {
        urlPage = 1;
        urlParam.set('page', urlPage);
        const currentUrl = new URL(window.location.href);
        const newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;

        window.history.replaceState({}, '', newUrl);
    }
    return { filterData, urlPage };
}

export default FetchSearchUrl