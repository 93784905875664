import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { NJVAddButton } from "./core-component";
import { Colors } from "./theme";


const { Title } = Typography

export const FormLabel = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span style={{ color: "red" }}>*</span> <span>{label}</span>
        </div>

    );
}

export const FormLabelOptional = (props) => {
    const { label } = props;
    return (
        <div style={{ marginBottom: 5 }}>
            <span>{label}</span>
        </div>
    );
}
export const ErrorLabel = (props) => {
    return (
        <div style={{ marginLeft: 5, marginBottom: 10 }}>
            <span style={{ color: "red" }}>( *required )</span>
        </div>

    );
}


export const TitleLevel3 = (props) => {
    const { label } = props;
    return (
        <span style={{ fontSize: 27, fontWeight: '600' }}>{label}</span>
    )
}

export const TitleLevel4 = (props) => {
    const { label } = props;
    return (
        <Title level={4}>{label}</Title>
    );
}

export const SubTitle = (props) => {
    const { label } = props;
    return (
        <div style={{ fontSize: 15, color: Colors.gray }}>{label}</div>
    );
}
export const SubContent = (props) => {
    const { label } = props;
    return (
        <div style={{ fontSize: 18, fontWeight: '600' }}>{label}</div>
    );
}

export const MainPageTitle = (props) => {
    const { title, route } = props;

    return (
        <Row style={{ marginBottom: "20px", alignItems: 'center' }}>
            <Col span={12}>
                <span style={{ fontSize: 25, fontWeight: '700' }}>{title}</span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
                <Link to={route}><NJVAddButton /></Link>
            </Col>
        </Row>
    );
}
