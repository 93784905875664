import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, Form, Modal, Row, Tag } from "antd";
import { default as React } from "react";
import { NJVInput, NJVLabel } from "../../components/core-component";
import { formatDateDigit } from "../../components/format";
import { TitleLevel3 } from "../../components/general-component";
import NJVTable from "../../components/njv-table";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { FORM_ITEM } from "../../core/constant";

const complaintType = {
    'BUSINESS_DEVELOPMENT': 'လုပ်ငန်းတိုးတက်ရန် အကြံပေးခြင်း',
    'SALARY_AND_BENEFITS': 'လစာနှင့်အကျိုးခံစားခွင့်',
    'WORKPLACE_RELATIONSHIPS': 'လုပ်ငန်းခွင်ဆက်ဆံရေး',
    'WORKING_HOURS_AND_LEAVE': 'အလုပ်ချိန်နှင့် နားရက်',
    'LEADERSHIP_AND_MANAGEMENT': 'ခေါင်းဆောင်မှုနှင့် စီမံခန့်ခွဲမှု',
    'WORKPLACE_ENV_AND_SUPPRORT': 'လုပ်ငန်းခွင်ပတ်ဝန်းကျင်နှင့် အထောက်အပံ့',
    'COMPLAINTS': 'တိုင်ကြားခြင်း',
    'OTHER_TOPICS': 'အခြားအကြောင်းအရာများ'
}

const complaint_collection = [
    {
        value: 'BUSINESS_DEVELOPMENT', label: 'လုပ်ငန်းတိုးတက်ရန် အကြံပေးခြင်း'
    },
    {
        value: 'SALARY_AND_BENEFITS', label: 'လစာနှင့်အကျိုးခံစားခွင့်'
    },
    {
        value: 'WORKPLACE_RELATIONSHIPS', label: 'လုပ်ငန်းခွင်ဆက်ဆံရေး'
    },
    {
        value: 'WORKING_HOURS_AND_LEAVE', label: 'အလုပ်ချိန်နှင့် နားရက်'
    },
    {
        value: 'LEADERSHIP_AND_MANAGEMENT', label: 'ခေါင်းဆောင်မှုနှင့် စီမံခန့်ခွဲမှု'
    },
    {
        value: 'WORKPLACE_ENV_AND_SUPPRORT', label: 'လုပ်ငန်းခွင်ပတ်ဝန်းကျင်နှင့် အထောက်အပံ့'
    },
    {
        value: 'COMPLAINTS', label: 'တိုင်ကြားခြင်း'
    },
    {
        value: 'OTHER_TOPICS', label: 'အခြားအကြောင်းအရာများ'
    }
]

class ComplaintPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            openModal: false,
            openDetailModal: false,
            complaintDetail: null,
        }
    }

    handleAddReason = async (reason) => {
        this.setState({
            isSubmitting: true
        })
        await ApiHandler({ url: Api.employee_complaints, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData: { closedReason: reason.closedReason }, specificId: this.state.complaintDetail?.id })
            .then((response) => {
                this.setState({
                    openModal: false
                })
                this.reFetch()
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isSubmitting: false,
                    openModal: false
                })
            })
    }
    
    render() {
        const { openModal, openDetailModal, complaintDetail, isSubmitting } = this.state

        const columns = [
            {
                title: 'Type',
                key: 'complaintType',
                dataIndex: 'complaintType',
                render: (value) => <>{complaintType[value]}</>
            },
            {
                title: 'Content',
                key: 'content',
                dataIndex: 'content'
            },
            {
                title: 'Details',
                key: 'action',
                align: 'center',
                render: (record) => !((record.employeeName && record.phoneNumber && record.officeAddress && record.position && record.department) || (record.closedReason && record.closedTime)) ? '-' : <Button type="link" onClick={() => this.setState({ openDetailModal: true, complaintDetail: record })} style={{ textDecoration: 'underline' }}>Details</Button>
            },
            {
                title: 'Status',
                key: 'complaintStatus',
                dataIndex: 'complaintStatus',
                align: 'center',
                render: (value, record) => {
                    return value === 'PENDING' ? (
                        <Button
                            type="primary"
                            onClick={() => this.setState({ openModal: true, complaintDetail: record })}
                        >
                            Add Closed Reason
                        </Button>
                    ) : (
                        <Tag color={record.complaintStatus === 'CLOSED' ? '#87d068' : '#ff4d4f'}>{value}</Tag>
                    )
                }
            },
        ]

        const filterColumn = [
            {
                key: 'complaintType',
                name: 'Select Complaint Type',
                type: FORM_ITEM.SELECT,
                selectCollection: complaint_collection
            },
        ]

        return (
            <>
                <Modal title="Reason" open={openModal} onCancel={() => this.setState({ openModal: false })} onOk={() => { }} centered footer={null}>
                    <Form
                        layout="vertical"
                        onFinish={this.handleAddReason}>
                        <Form.Item name="closedReason" rules={[{ required: true, message: '*(requried)' }]} >
                            <NJVInput
                                bgcolor={Theme.colors.secondary}
                                size="large"
                                showCount
                                onChange={() => { }}
                                style={{
                                    width: '100%',
                                }}
                                maxLength={250}
                                placeholder="Please enter closed reason."
                                istextareainput="true"
                            />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end', marginBottom: 0 }}>
                            <Button style={{ marginRight: 20 }} onClick={() => this.setState({ openModal: false })}>Cancel</Button>
                            <Button type="primary" htmlType="submit" loading={isSubmitting}>Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="Detail" open={openDetailModal} onCancel={() => this.setState({ openDetailModal: false })} footer={null}>
                    <Card style={{ marginBottom: 30, padding: 10 }}>
                        <Row gutter={[16, 16]}>
                            {
                                complaintDetail?.employeeName &&
                                <Col span={12}>
                                    <NJVLabel label="Employee Name" />
                                    <div>{complaintDetail.employeeName}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.phoneNumber &&
                                <Col span={12}>
                                    <NJVLabel label="Phone Number" />
                                    <div>{complaintDetail.phoneNumber}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.officeAddress &&
                                <Col span={12}>
                                    <NJVLabel label="Office Address" />
                                    <div>{complaintDetail.officeAddress}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.position &&
                                <Col span={12}>
                                    <NJVLabel label="Position" />
                                    <div>{complaintDetail.position}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.department &&
                                <Col span={12}>
                                    <NJVLabel label="Department" />
                                    <div>{complaintDetail.department}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.closedReason &&
                                <Col span={12}>
                                    <NJVLabel label="Closed Reason" />
                                    <div>{complaintDetail.closedReason}</div>
                                </Col>
                            }
                            {
                                complaintDetail?.closedTime &&
                                <Col span={12}>
                                    <NJVLabel label="Closed Time" />
                                    <div>{formatDateDigit(complaintDetail.closedTime)}</div>
                                </Col>
                            }
                        </Row>
                    </Card>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Ninja Complaint"} />
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            apiUrl={Api.employee_complaints}
                            filters={filterColumn}
                            handleRefetch={fetchData => this.reFetch = fetchData}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(ComplaintPage)