
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import cookieSlice from './cookie-slice';
import LocationSlice from './location-slice';
import notiSlice from './noti-slice';
import orderFormSlice from './order-form-slice';
import menuSlice from './menu-slice';
import profileSlice from './profile-slice';
import enumSlice from './enum-slice';

const persistConfig = {
  key: 'ninija-van-admin-root',
  storage,
}

const rootReducer = combineReducers({
  cookie: cookieSlice,
  orderform: orderFormSlice,
  location: LocationSlice,
  noti: notiSlice,
  menu: menuSlice,
  profile: profileSlice,
  enum: enumSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store)
export { persistor };
export default store