import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Row, Select, Skeleton, Space, Table } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import FetchSearchUrl from "../../../components/fetch-searchUrl";
import { TitleLevel3 } from "../../../components/general-component";
import SearchFilter from "../../../components/searchFilter";
import SearchPagination from "../../../components/searchPagination";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiPostWithParam } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class ShipperApprovalPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],

            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            searchRole: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            roles: [],
            isDataFetching: false,
            filterData: { approvalStatus: 'PENDING' }
        }
    }

    componentDidMount = () => {
        const existingFilterData = this.state.filterData || {};
        const defaultFilter = existingFilterData.approvalStatus ? { approvalStatus: existingFilterData.approvalStatus } : { approvalStatus: 'PENDING' };

        const { filterData, urlPage } = FetchSearchUrl(defaultFilter)

        if (urlPage) {
            this.setState({
                filterData
            }, () => this.fetchData(urlPage))
        }
    }
    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state
        this.setState({
            isDataFetching: true
        })

        var params = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            ...filterData
        }
        await ApiFilter(Api.shipper_filter, params, this.props)
            .then(response => {
                let updatedList = []
                response.content && response.content.map(order => {
                    const orderTime = dayjs(order.createdDate, 'YYYY-MM-DD');
                    if (orderTime) {
                        order = { ...order, createdDate: orderTime.format('DD MMM,YYYY') }
                    }
                    updatedList.push(order)
                })
                this.setState({
                    data: updatedList,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false
                })
            })
            .catch(error => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    confirmShipperRegister = async (shipperId) => {
        try {
            const params = {
                shipper_id: shipperId
            }
            await ApiPostWithParam(Api.shipper_approve, params, {}, this.props)
        } catch (error) {

        }
    }

    handleSelect = (value) => {
        this.fetchData(null, value)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }
    search = () => {
        const { filterData } = this.state;

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    render() {
        const { data, totalPagination, page, pageSize, isDataFetching, filterData } = this.state
        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (shipper) => (
                    <span>{shipper.firstName} {shipper.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Business Name',
                key: 'businessName',
                render: (shipper) => (
                    <>
                        {
                            shipper.business ?
                                <span>{shipper.business.businessName}</span>
                                :
                                <></>

                        }
                    </>

                ),
            },
            {
                title: 'Township/City',
                key: 'township',
                render: (shipper) => (
                    <>
                        {
                            shipper.township ?
                                <span>{shipper.township.name}</span>
                                :
                                <></>

                        }
                    </>
                ),
            },

            {
                title: 'Issue Date',
                key: 'createdDate',
                dataIndex: 'createdDate'
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                render: (shipper) => (
                    <Space>
                        <Link to={`${CustomPath.shipper_request_detail}/${shipper.id}`} state={{ route: `${CustomPath.shipper_approval}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle" style={{ paddingLeft: 10, paddingRight: 10 }}> View </Button></Link>
                    </Space>
                ),
            },
        ]
        return (
            <Row gutter={[16, 16]}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Shipper Approval"} />
                </Col>
                <Col span={6}>
                    <Input
                        value={filterData?.businessName}
                        onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                        style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by business name"
                    />
                </Col>
                <Col span={6}>
                    <Input
                        value={filterData?.phone}
                        onChange={(event) => this.updateFilterData('phone', event.target.value)}
                        style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder="Search by phone"
                    />
                </Col>
                <Col span={6} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <Select
                        size="large"
                        className="custom-selector-gray"
                        value={filterData?.approvalStatus}
                        defaultValue="PENDING"
                        onChange={(value) => this.updateFilterData('approvalStatus', value)}
                        style={{
                            width: '100%'
                        }}
                        options={[{
                            value: 'PENDING',
                            label: 'PENDING',
                        }, {
                            value: 'REJECT',
                            label: 'REJECT',
                        },
                        ]}
                    />
                </Col>
                <Col span={6}>
                    <Button size="large" shape="square" onClick={() => this.search()} type="primary" style={{ width: '100%' }}>Search</Button>
                </Col>
                <Col span={24} style={{ marginTop: 20 }}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :

                            <Table
                                style={{ borderRadius: 10 }}
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns} dataSource={data}
                                rowClassName={getRowClassName} />
                    }
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(ShipperApprovalPage)