import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Checkbox, Col, Form, Input, Modal, Row, Skeleton, Space, Table, Tag, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PostalCodeValidator } from "../../components/validator";
import Api from "../../network/api";
import { ApiDelete, ApiFilter, ApiPost, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import FetchSearchUrl from "../../components/fetch-searchUrl";
import SearchFilter from "../../components/searchFilter";


class TownshipPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchName: '',
            pageSize: 100,
            totalPagination: 0,
            page: 1,
            searchValue: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            updateTownship: {},
            divisionId: null,
            isDataFetching: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        const { filterData, urlPage } = FetchSearchUrl()
        const { searchName } = this.state;

        if (urlPage) {
            this.setState({
                searchName: filterData?.name || searchName
            }, () => this.fetchData(urlPage))
        }
    }

    //filter 
    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    search = () => {
        const { searchName } = this.state;

        const filterData = {
            name: searchName || ''
        }

        const newUrl = SearchFilter(filterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    //fetch data
    fetchData = async (pageNumber) => {
        const { page, searchName, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var params = {
                name: searchName,
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize,
                divisionId: this.props.params.id
            }
            const response = await ApiFilter(Api.township_filter, params, this.props)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
        })
    }



    //create Section
    saveTownship = async (formData) => {
        try {
            const response = await ApiPost(Api.township,
                {
                    "name": formData.name,
                    "shortCode": formData.shortCode,
                    "postalCode": formData.postalCode,
                    "sortCode": formData.sortCode,
                    "active": formData.active,
                    "enableHomeDelivery": formData.enableHomeDelivery,
                    "enableBusGatePostOfficeService": formData.enableBusGatePostOfficeService,
                    "division": {
                        "id": this.props.params.id
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.fetchData()
            }
        } catch (error) {
        }
        this.closeTownshipCreateForm()
    }


    //update Section
    updateTownship(township) {
        this.setState({
            updateTownship: township,
            openUpdateModal: true
        })
    }

    updateTownshipConfirm = async (formData) => {
        const { updateTownship } = this.state
        if (updateTownship.name === null || updateTownship.name === '') {
            return;
        }
        const numberPattern = /^[0-9]+$/;
        if (!updateTownship.postalCode || updateTownship.postalCode.length < 5 || updateTownship.postalCode.length > 5 || !numberPattern.test(updateTownship.postalCode)) {
            message.error("Invalid postal code")
            return;
        }
        try {
            const response = await ApiPut(Api.township, updateTownship.id,
                {
                    "id": updateTownship.id,
                    "name": formData.name,
                    "nameInMyanmar": formData.nameInMyanmar,
                    "shortCode": formData.shortCode,
                    "postalCode": formData.postalCode,
                    "sortCode": formData.sortCode,
                    "active": formData.active,
                    "enableHomeDelivery": formData.enableHomeDelivery,
                    "enableBusGatePostOfficeService": formData.enableBusGatePostOfficeService,
                }, this.props
            )
            if (response.status === 200) {
                this.fetchData()
            }
            this.setState({ updateTownship: {} })
            this.closeTownshipUpdateForm()
        } catch (error) {

        }
        this.closeTownshipUpdateForm()
    }

    // delete Section
    deleteTownship = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteTownshipConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.township, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber)
    }
    render() {
        const { isDataFetching, data, openModal, totalPagination, page, pageSize, openCreateModal, openUpdateModal, searchName } = this.state

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'MM',
                dataIndex: 'nameInMyanmar',
                key: 'nameInMyanmar'
            },
            {
                title: 'Short Code',
                dataIndex: 'shortCode',
                key: 'shortCode'
            },
            {
                title: 'Postal Code',
                dataIndex: 'postalCode',
                key: 'postalCode'
            },
            {
                title: 'Sorting Code',
                dataIndex: 'sortCode',
                key: 'sortCode'
            }, {
                title: 'Home Delivery',
                key: 'enableHomeDelivery',
                render: (_, { enableHomeDelivery }) => (
                    <>
                        {
                            (
                                enableHomeDelivery ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),
            }, {
                title: 'Bus Gate & Post Office',
                key: 'enableBusGatePostOfficeService',
                render: (_, { enableBusGatePostOfficeService }) => (
                    <>
                        {
                            (
                                enableBusGatePostOfficeService ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (township) => (
                    <Space>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.updateTownship(township)}><EditOutlined /></Button>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteTownship(township.id)}><DeleteOutlined /></Button> */}

                    </Space>
                ),
            },
        ]

        return (
            <>

                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteTownshipConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                {this.townshipCreateForm(openCreateModal)}
                {this.townshipUpdateForm(openUpdateModal)}
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.division}>Division</Link>,
                                },
                                {
                                    title: 'Township',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={11} style={{ textAlign: 'right' }}>
                        {/* <NJVAddButton handleClick={() => this.openTownshipCreateForm()} /> */}
                    </Col>
                    <Col span={1} />
                </Row>
                <Row style={{ marginBottom: 30 }}>
                    <Col span={24} className="custom-btn">
                        <Input
                            value={searchName}
                            onChange={this.changeSearchName}
                            style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                        />
                        <Button size="large" onClick={() => this.search()} type="primary" >Search</Button>

                    </Col>
                </Row>
                {
                    isDataFetching ?
                        <Skeleton active />
                        :
                        <Table
                            // className="custom-table"
                            style={{ borderRadius: 10 }}
                            pagination={{
                                position: 'top' | 'bottom',
                                total: totalPagination,
                                current: page,
                                showSizeChanger: false,
                                onChange: this.handlePaginationChange,
                                defaultPageSize: pageSize
                            }}
                            columns={columns}
                            dataSource={data}
                            rowClassName={getRowClassName}
                        />
                }

            </>


        )
    }

    // UI SECTION
    townshipCreateForm = (modalState) => {
        return (
            <Modal
                title="Township Form"
                open={modalState}
                closeIcon={null}
                footer={null}
            >
                <Form
                    layout="vertical"
                    ref={this.inputRef}
                    onFinish={this.saveTownship}>
                    <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter name"} />
                    </Form.Item>
                    <Form.Item name="shortCode" label={"Short Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter short code"} />
                    </Form.Item>
                    <Form.Item name="sortCode" label={"Sorting Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter sorting code"} />
                    </Form.Item>
                    <Form.Item name="postalCode" label={"Postal Code"} rules={[{ validator: PostalCodeValidator }]}>
                        <Input size="large" placeholder={"Enter postal code"} style={{ width: '100%', background: '#f1f1f1' }} />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        valuePropName="checked">
                        <Checkbox>Active</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="enableHomeDelivery"
                        valuePropName="checked">
                        <Checkbox>Enable Home Delivery</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="enableBusGatePostOfficeService"
                        valuePropName="checked">
                        <Checkbox>Enable Bus Gate and Post Office Service</Checkbox>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'end', marginTop: 20 }}>
                        <Button style={{ marginRight: '20px' }} onClick={() => this.closeTownshipCreateForm()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Create</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    openTownshipCreateForm = () => {
        this.setState({
            openCreateModal: true
        })
    }

    closeTownshipCreateForm = () => {
        this.inputRef.current.resetFields()
        this.setState({
            openCreateModal: false,
        })
    }


    townshipUpdateModalChange = () => {
        const { updateTownship } = this.state
        if (this.updateRef && this.updateRef.current) {
            this.updateRef.current.setFieldsValue(updateTownship)
        } else {
            this.updateRef = React.createRef()
        }
    }
    townshipUpdateModalOnClose = () => {
        this.updateRef = React.createRef();
    }



    townshipUpdateForm = (modalState) => {
        return (
            <Modal
                title="Township Update"
                afterOpenChange={() => this.townshipUpdateModalChange()}
                afterClose={() => this.townshipUpdateModalOnClose()}
                open={modalState}
                closeIcon={null}
                footer={null}
            >
                <Form
                    layout="vertical"
                    ref={this.updateRef}
                    onFinish={this.updateTownshipConfirm}>
                    <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter name"} />
                    </Form.Item>
                    <Form.Item name="nameInMyanmar" label={"Name In Myanmar"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter name"} />
                    </Form.Item>
                    <Form.Item name="shortCode" label={"Short Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter short code"} />
                    </Form.Item>
                    <Form.Item name="sortCode" label={"Sorting Code"} rules={[{ required: true, message: '*(requried)' }]}>
                        <Input style={{ background: '#f1f1f1' }} placeholder={"Enter sorting code"} />
                    </Form.Item>
                    <Form.Item name="postalCode" label={"Postal Code"} rules={[{ validator: PostalCodeValidator }]}>
                        <Input size="large" placeholder={"Enter postal code"} style={{ width: '100%', background: '#f1f1f1' }} />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        valuePropName="checked">
                        <Checkbox>Active</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="enableHomeDelivery"
                        valuePropName="checked">
                        <Checkbox>Enable Home Delivery</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="enableBusGatePostOfficeService"
                        valuePropName="checked">
                        <Checkbox>Enable Bus Gate and Post Office Service</Checkbox>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'end', marginTop: 20 }}>
                        <Button style={{ marginRight: '20px' }} onClick={() => this.closeTownshipUpdateForm()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    closeTownshipUpdateForm = () => {

        this.updateRef.current = null;
        this.setState({
            openUpdateModal: false,
            updateTownship: {}
        })

    }
}
export default compose(withRouter)(TownshipPage);

