import { MinusCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Divider, Form, Image, Input, Modal, Row, Select, Skeleton, Table, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import DynamicEnumSelect from "../../components/fetch-enum";
import Theme from "../../components/theme";
import { GetListFromEnumObject } from "../../core/nvm-utils";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, ApiPutWithFormData } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import NotificationChannelAddShipperToChannel from "./notification-channel-add-shipper-to-channel";

const { TextArea } = Input

const SPECIFIC_USER = "SPECIFIC USER"
const USER_TYPE = "USER TYPE"



class NotificationChannelUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: {},
            userTypes: [],
            notificationTypes: [],
            fileList: [],
            shippers: [],
            selectedRowKeys: [],
            pageSize: 100,
            totalPagination: 0,
            page: 1,
            isPushNotificaton: false,
            is_selected_partnership_admin: false,
            is_updating_data: false,
            is_shipper_data_fetching: true,
            openShippersModal: false,
            push_user_type: SPECIFIC_USER
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchUserTypes()
        this.fetchNotificationType()
        this.fetchData()
    }

    handleChange = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.forEach(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList: updateFileList
        })
    }

    fetchUserTypes = async () => {
        try {
            const requestParams = {
                enumType: 'USER_TYPE'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.setState({
                    userTypes: GetListFromEnumObject(response),
                });
            }
        } catch (error) {
        }
    }

    fetchSubscribeShippers = async (pageNumber) => {
        const { page, pageSize } = this.state
        this.setState({
            is_shipper_data_fetching: true
        })
        try {
            const requestParams = {
                channelId: this.state.data.id,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiHandler({ url: Api.shipper_notification_channel_subscribers, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let shippersTemp = []
                response?.content?.forEach((res) => {
                    shippersTemp.push({
                        key: res.id,
                        fullName: res.fullName,
                        phoneNumber: res.phoneNumber,
                        businessName: res?.businessResponseDTO?.businessName

                    });
                })
                this.setState(prevState => ({
                    shippers: shippersTemp,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            is_shipper_data_fetching: false
        })
    }

    fetchData = async () => {
        try {
            let response = await ApiHandler({ url: Api.notification_channel, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            if (response) {
                if (response.pushNotificationTime) {
                    const notiTime = dayjs(response.pushNotificationTime, 'YYYY-MM-DD HH:mm:ss');
                    response = { ...response, pushNotificationTime: notiTime }
                }
                let is_sp_admin = false
                response.userTypes && response.userTypes.forEach(userType => {
                    if (userType === 'SP_ADMIN') {
                        is_sp_admin = true
                    }
                })
                this.updateRef.current.setFieldsValue({ ...response, content: response.content.replace(/\r\n/g, "\n") });
                this.setState({
                    data: response,
                    isPushNotificaton: response.isAutoPushNotification ? response.isAutoPushNotification : false,
                    is_selected_partnership_admin: is_sp_admin
                }, () => { this.fetchSubscribeShippers() })
            }
        } catch (error) {
        }
    }
    fetchNotificationType = async () => {
        try {
            var requestParams = {
                'enumType': 'NOTIFICATION'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let notificationTemp = []
                response.forEach((res) => {
                    notificationTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    notificationTypes: notificationTemp
                })
            }
        } catch (error) {
        }
    }

    updateNotificationChannel = async (values) => {
        const { data } = this.state
        this.setState({
            is_updating_data: true
        })
        try {
            let formData = new FormData()
            formData.append('id', data.id);
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('active', values.active);
            formData.append('notificationType', values.notificationType)
            formData.append('partnerAdminSpecialCode', values.partnerAdminSpecialCode ? values.partnerAdminSpecialCode : '')
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                formData.append('file', values.photoUrl.file.originFileObj)
            }
            if (values.isAutoPushNotification) {
                if (values.pushNotificationTime) {
                    const currentTime = dayjs()
                    if (currentTime > values.pushNotificationTime) {
                        message.error("Invalid selected push time")
                        return
                    }
                    let pushTime = values.pushNotificationTime.format("YYYY-MM-DD HH:mm:ss")
                    formData.append('isAutoPushNotification', values.isAutoPushNotification);
                    formData.append('pushNotificationTime', pushTime);
                } else {
                    message.error("Please select push time")
                    return
                }
            } else {
                formData.append('isAutoPushNotification', false);
                formData.append('pushNotificationTime', "");
            }
            const response = await ApiHandler({ url: Api.notification_channel, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, specificId: data.id, requestData: formData });
            // const response = await ApiPutWithFormData(Api.notification_channel, specificId : data.id, formData, this.props)
            if (response.status === 200) {
                this.props.navigate(CustomPath.notification_channel)
            }
        } catch (error) {
        }
        this.setState({
            is_updating_data: false
        })
    }


    removeShippersToChannel = async () => {
        const { selectedRowKeys, data } = this.state
        if (selectedRowKeys && selectedRowKeys.length === 0) {
            message.error("Please select at least one shipper")
            return
        }
        try {
            const requestData = {
                channelId: data.id,
                userIds: selectedRowKeys
            }
            const response = await ApiHandler({ url: Api.notification_channel_remove_subscriber, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData, customMessage: 'Successfully Removed' })
            if (response) {
                this.fetchSubscribeShippers()
                this.setState({ selectedRowKeys: [] })
            }
        } catch (error) {
            message.error("Fail to add shippers to channel")
        }
    }

    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
        const isBefore2PM = currentDate.isBefore(targetTime);
        if (isBefore2PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }

    disabledDate = (current) => {
        return current && current < dayjs().startOf('day')
    };

    onSelectAutoPushNotification = (event) => {
        this.setState({
            isPushNotificaton: event.target.checked
        })
    }

    onChangeUserType = (selectedValue) => {
        let partnerList = ['SP_ADMIN']
        let is_selected_partner_ship = false
        selectedValue.forEach(value => {
            if (value === 'SP_ADMIN' || value === 'SP_STAFF') {
                is_selected_partner_ship = true
            }
        })
        if (is_selected_partner_ship) {
            this.setState({
                is_selected_partnership_admin: true
            })
            this.updateRef.current.setFieldsValue({ userTypes: partnerList })
        } else {
            this.setState({
                is_selected_partnership_admin: false
            })
        }
    }
    handlePaginationChange = (pageNumber) => {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys && selectedRowKeys.length !== 0) {
            message.error("Please add selected shipper to channel first")
        } else {
            this.fetchSubscribeShippers(pageNumber);
        }

    };

    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    };

    onAddShippers = () => {
        this.setState({ openShippersModal: false })
        this.fetchSubscribeShippers()
    }

    render() {
        const { navigate } = this.props
        const { page, totalPagination, pageSize, shippers, fileList, data, isPushNotificaton, is_updating_data, openShippersModal, is_shipper_data_fetching, selectedRowKeys } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'fullName',
            }, {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName'
            }
        ]
        return (
            <>
                <Modal
                    title="Add Shippers to Channel"
                    style={{ top: 20, padding: 0, left: 0 }}
                    width="100%"
                    open={openShippersModal}
                    footer={null}
                    onCancel={() => this.setState({ openShippersModal: false })}
                >
                    <NotificationChannelAddShipperToChannel channelId={data.id} onAddShippers={this.onAddShippers} />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.notification_channel}>Notification Channel</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Notification Channel"}
                    ref={this.updateRef}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.notification_channel)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        initialValues={{ active: true }}
                        onFinish={this.updateNotificationChannel}>
                        <Row gutter={[16, 8]}>
                            {
                                data.photoUrl ?
                                    <Col span={24}>
                                        <Image
                                            style={{
                                                marginTop: 5,
                                                borderRadius: 9,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.setState({ viewPhoto: true })}
                                            src={data.photoUrl}
                                            placeholder={true}
                                            loading={"eager"}
                                            width={200}
                                        />
                                        <br />
                                        <Button
                                            style={{ marginTop: 10, marginBottom: 20 }}
                                            type="primary"
                                            onClick={() => this.setState({ data: { ...data, photoUrl: null } })}
                                        >Remove</Button>
                                    </Col>
                                    :
                                    <Col span={24}>
                                        <Form.Item name="photoUrl">
                                            <Upload
                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                showUploadList={{ showPreviewIcon: false }}
                                                onChange={this.handleChange}

                                            >
                                                {fileList.length >= 1 ? null :
                                                    <div style={{ padding: 50 }}>
                                                        <PlusOutlined />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Upload
                                                        </div>
                                                    </div>}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                            }
                            <Col span={12}>
                                <Form.Item name="notificationType" label={"Notification Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect enumtype='NOTIFICATION' size='large' placeholder={"Select notification type"} width="100%" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="userType" label={"Recipient Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect disabled enumtype='USER_TYPE' size='large' placeholder={"Select recipient type"} width="100%" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="title" label={"Title"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" placeholder={"Enter title"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="content" label={"Content"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 200, background: '#fff' }}
                                        maxLength={1000}
                                        showCount={true}
                                        placeholder={"Enter content"} />
                                </Form.Item>
                            </Col>


                            {
                                isPushNotificaton &&
                                <Col span={24}>
                                    <Form.Item name="pushNotificationTime" label={"Select auto push notification date"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                        <DatePicker
                                            showTime
                                            disabledDate={this.disabledDate}
                                            size="large"
                                            allowClear={true}
                                            style={{ cursor: 'pointer' }}
                                            format={'YYYY-MM-DD HH:mm:ss'}
                                        />
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={24}>
                                <Row gutter={[24, 4]}>
                                    <Col>
                                        <Form.Item
                                            name="pushToSpecificUser"
                                            valuePropName="checked">
                                            <Checkbox disabled>Send to specific user</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col>
                                        <Form.Item
                                            name="isAutoPushNotification"
                                            onChange={this.onSelectAutoPushNotification}
                                            valuePropName="checked">
                                            <Checkbox>Enable Auto Push Notification</Checkbox>
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={is_updating_data}>Update</Button>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>

                            {
                                data.pushToSpecificUser &&

                                <>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button type="primary" disabled={selectedRowKeys.length === 0 ? true : false} onClick={() => this.removeShippersToChannel()} icon={<MinusCircleFilled />}>
                                            Remove Shipper
                                        </Button>
                                        <Button type="primary" onClick={() => this.setState({ openShippersModal: true })} icon={<PlusOutlined />}>
                                            Add More Shipper
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        {
                                            is_shipper_data_fetching ?
                                                <Skeleton active />
                                                :
                                                <Table
                                                    rowSelection={{
                                                        selectedRowKeys,
                                                        onChange: this.onSelectChange,
                                                    }}
                                                    style={{ borderRadius: 10 }}
                                                    pagination={{
                                                        position: 'top' | 'bottom',
                                                        total: totalPagination,
                                                        current: page,
                                                        defaultPageSize: pageSize,
                                                        showSizeChanger: false,
                                                        onChange: this.handlePaginationChange
                                                    }}

                                                    columns={columns}
                                                    dataSource={shippers}
                                                    rowClassName={getRowClassName} />
                                        }
                                    </Col>
                                </>

                            }

                        </Row>
                    </Form>
                </Card >
            </>
        );
    }
}

export default compose(withRouter)(NotificationChannelUpdate)