import React, { useEffect, useState } from 'react'
import { GetListFromEnumObject } from '../core/nvm-utils'
import { ApiHandler } from '../network/network-manager';
import Api from '../network/api';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { ConfigProvider, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { enumOption, updateEnumOptions } from '../redux/enum-slice';

const DynamicEnumSelect = (props) => {
    // const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const options = useSelector((state) => enumOption(state, props.enumtype));

    useEffect(() => {
        if (!options[props.enumtype]?.length) {
            fetchData()
        }
    }, [props.enumtype])

    const fetchData = async () => {
        setLoading(true)
        var requestParams = {
            'enumType': props.enumtype
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                // setOptions(GetListFromEnumObject(response))
                const optionsList = GetListFromEnumObject(response);
                dispatch(updateEnumOptions({ enumType: props.enumtype, options: optionsList }))
                // dispatch(clearEnumOptions())
            }).catch((error) => { })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: `${props?.bgcolor ? props?.bgcolor : '#fff'}`,
            }
        }}>
            <Select
                loading={loading}
                options={options[props.enumtype]}
                {...props}
            />
        </ConfigProvider>

    )
}

export default DynamicEnumSelect