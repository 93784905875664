import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { divisionCollection, townshipCollectionMap, treeData } from '../redux/location-slice';
import { menuList, menuMap } from '../redux/menu-slice';
import { advanceOrderList, selectedShipperInformation, senderInformation } from '../redux/order-form-slice';
import { roleType } from '../redux/profile-slice';


const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const advanceOrderDraftList = useSelector(advanceOrderList)
    const senderInfo = useSelector(senderInformation)
    const selectedShipperInfo = useSelector(selectedShipperInformation)
    const menuAccessList = useSelector(menuList)
    const menuAccessMap = useSelector(menuMap)
    const role = useSelector(roleType)
    const division_collection = useSelector(divisionCollection)
    const township_collection_map = useSelector(townshipCollectionMap)
    const tree_data = useSelector(treeData)
    return (

        <WrappedComponent
            {...props}
            params={params}
            navigate={navigate}
            location={location}
            dispatch={dispatch}
            advanceOrderDraftList={advanceOrderDraftList}
            menuAccessList={menuAccessList}
            menuAccessMap={menuAccessMap}
            senderInfo={senderInfo}
            selectedShipperInfo={selectedShipperInfo}
            roleType={role}
            divisionCollection={division_collection}
            townshipCollectionMap={township_collection_map}
            treeData={tree_data}
        />
    );
};
export default withRouter;