import { Button, Col, DatePicker, message, Row } from "antd";
import React from "react";
import withRouter from "../network/with-router";
import { compose } from "@reduxjs/toolkit";
import { ApiHandler } from "../network/network-manager";
import { HTTP_METHOD } from "../network/httpMethod";
import Theme from "../components/theme";
import dayjs from "dayjs";

class TokenCampaign extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            date: null,
            isTokenGenerating: false,
            isPushNotification: false
        }
    }

    generateToken = async () => {
        this.setState({ isTokenGenerating: true })
        await ApiHandler({ url: "api/v1/jan-campaign/generate-token-campaign", method: HTTP_METHOD.POST, requestParams: { date: dayjs(this.state.date).format('YYYY-MM-DD') }, disableShowMessage: true })
            .then(() => { message.success("Token Generated Successful") })
            .catch(() => { })
            .finally(() => this.setState({ isTokenGenerating: false }))
    }
    pushNotifications = async () => {
        this.setState({ isPushNotification: true })
        await ApiHandler({ url: "api/v1/jan-campaign/push-noti-token-campaign", method: HTTP_METHOD.POST, requestParams: { date: dayjs(this.state.date).format('YYYY-MM-DD') }, disableShowMessage: true })
            .then(() => { message.success("Notification Push Successful") })
            .catch(() => { })
            .finally(() => this.setState({ isPushNotification: false }))
    }
    render() {
        return (
            <Row gutter={[16, 16]} >
                <Col span={6}>
                    <DatePicker size="large" onChange={(date) => this.setState({ date })} style={{ backgroundColor: Theme.colors.input_bg_color, width: '100%' }} />
                </Col>
                <Col span={18} />
                <Col span={3}>
                    <Button type="primary" size="large" onClick={() => this.generateToken()} loading={this.state.isTokenGenerating} style={{ width: '100%' }}>Generate Token</Button>
                </Col>
                <Col span={3}>
                    <Button type="primary" size="large" onClick={() => this.pushNotifications()} loading={this.state.isPushNotification} style={{ width: '100%' }}>Push Notification</Button>
                </Col>
            </Row >
        );
    }
}
export default compose(withRouter)(TokenCampaign)