import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Input, message, Modal, Radio, Row, Select, Upload } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, checkValidFileSize, RestrictPhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
class StoreForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            previewImage: null,
            previewTitle: null,
            currentDate: '',
            logoFileList: [],
            isLoading: false,
            previewOpen: false,
        };
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    saveStoreForm = async (values) => {
        this.setState({
            isLoading: true
        })
        if (values.password !== values.confirmPassword) {
            message.destroy()
            message.error("Confirm password does not match")
            this.setState({
                isLoading: false
            })
            return
        }
        let formData = new FormData()
        formData.append('storeName', values.storeName);
        formData.append('ninjaStoreType', values.ninjaStoreType);
        formData.append('phoneNumber', values.phoneNumber);
        formData.append("password", values.password)
        formData.append('description', values.description ? values.description : '');
        formData.append("statusFlag", values.statusFlag)

        if (values?.logo?.file) {
            formData.append('storeLogo', values.logo.file)
        }
        await ApiHandler({ url: Api.store_create, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => { this.props.navigate(CustomPath.store) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (type, uploadObject) => {

        if (checkValidFileSize(uploadObject.file)) {
            if (type === 'logo') {
                this.setState({
                    logoFileList: uploadObject.fileList
                })
            }
        }
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };

    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }

    render() {
        const { navigate } = this.props
        const { isLoading, logoFileList, previewOpen, previewImage, previewTitle } = this.state

        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.store}>Ninja Store</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Store Form"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.store)}>Back</Button>}
                >
                    <Form
                        layout="vertical"
                        initialValues={{ statusFlag: 'ACTIVE' }}
                        onFinish={this.saveStoreForm}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Form.Item name="logo" label={"Upload Logo"} rules={[{ required: true, message: 'Logo is required!' }]}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-circle"
                                        fileList={logoFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('logo', obj)}
                                        beforeUpload={() => false}
                                    >
                                        {logoFileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={12} >
                                <Form.Item name="storeName" label={"Store Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter store name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="phoneNumber" label={"Phone Number"} rules={[
                                    { required: true, message: '*(requried)' },
                                    { validator: RestrictPhoneNumberValidator }]}>
                                    <Input placeholder={"Enter phone number"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="confirmPassword" label={"Confirm Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter confirm password" />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="ninjaStoreType" label={"Store Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select store type"
                                        options={[
                                            {
                                                label: 'Restaurant',
                                                value: 'RESTAURANT',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="description" label={"Description"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter description"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="statusFlag">
                                    <Radio.Group>
                                        <Radio value={'ACTIVE'}>Active</Radio>
                                        <Radio value={'INACTIVE'}>Inactive</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(StoreForm)