import { InboxOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Form, message, Modal, Row, Space, Table, Tag } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { Component } from 'react';
import { NJVInput } from '../../components/core-component';
import { SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined } from "../../components/custom-svg";
import { TitleLevel3 } from '../../components/general-component';
import Theme from '../../components/theme';
import withRouter from '../../network/with-router';

const shipperColumns = [
    {
        title: 'Shipper Name',
        key: 'shipperName',
        dataIndex: 'shipperName'
    },
    {
        title: 'Min of Delivery Completed',
        key: 'minDeliveryCompleted',
        dataIndex: 'minDeliveryCompleted',
    },
    {
        title: 'Max of Delivery Completed',
        key: 'minDeliveryCompleted',
        dataIndex: 'minDeliveryCompleted',
    },
    {
        title: 'Total Remittance Value',
        key: 'totalRemittanceValue',
        dataIndex: 'totalRemittanceValue',
    },
    {
        title: 'Invoice Number',
        key: 'invoiceNumber',
        dataIndex: 'invoiceNumber',
    },
    {
        title: 'Invoice Date',
        key: 'invoiceDate',
        dataIndex: 'invoiceDate',
    },
    {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        render: (value) => (
            <Space>
                <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', value)}><SvgPdfOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
                <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', value)}><SvgExcelOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
                <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', value)}><SvgPngOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
            </Space>
        )
    }
]

class InvoicePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openUploadExcelModal: false,
            excelFile: null,
            isUploading: false,
            invoiceData: [{
                fileName: 'Invoice_2021_01_01',
                description: 'Invoice for January 2021',
                status: 'READY',
                createdDate: '2021-01-01 09:00 PM',
                list: [
                    {
                        shipperName: 'Shipper 1',
                        minDeliveryCompleted: 1,
                        maxDeliveryCompleted: 2,
                        totalRemittanceValue: 1000,
                        invoiceNumber: 'INV_2021_01_01',
                        invoiceDate: '2021-01-01'
                    }
                ]
            }],
            shipperInvoicedata: [
                {
                    shipperName: 'Shipper 1',
                    minDeliveryCompleted: 1,
                    maxDeliveryCompleted: 2,
                    totalRemittanceValue: 1000,
                    invoiceNumber: 'INV_2021_01_01',
                    invoiceDate: '2021-01-01'
                }
            ],
            shipperView: false
        }
    }

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    onSearch = ({ invoiceId }) => {
        if (invoiceId) {
            this.setState({
                shipperView: true
            })
        } else {
            this.setState({
                shipperView: false
            })
        }
    }

    // handleFileUpload = (file) => {
    //     console.log(file);

    // }

    render() {
        const { openUploadExcelModal, invoiceData, shipperInvoicedata, shipperView } = this.state;

        const draggerProps = {
            name: 'file',
            multiple: false,
            listType: 'picture',
            previceFile: true,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.setState({ excelFile: file })
            },
        };

        const columns = [
            {
                title: 'File Name',
                key: 'fileName',
                dataIndex: 'fileName'
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description'
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                render: (value) => value && <Tag color={`${value === 'FAIL' ? '#f50' : value === 'READY' ? '#2db7f5' : value === 'PENDING' ? 'gray' : 'default'}`}>{value}</Tag>
            },
            {
                title: 'Created Date',
                key: 'createdDate',
                dataIndex: 'createdDate'
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                align: 'center',
                render: (value) => (
                    <Space>
                        {/* <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('pdf', value)}><SvgPdfOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button> */}
                        <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('excel', value)}><SvgExcelOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
                        {/* <Button size={'large'} type="text" shape="circle" onClick={() => this.downloadInvoice('png', value)}><SvgPngOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button> */}
                    </Space>
                )
            },
            // {
            //     title: '',
            //     key: 'view',
            //     dataIndex: 'view',
            //     render: (value) => <Button type="link" onClick={() => this.setState({ shipperView: true })}>View List</Button>
            // }
        ]

        return (
            <>
                <Modal
                    title={"Upload Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeModal()}
                    className='custom-modal'
                >
                    <Dragger
                        // disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {/* {
                                isUploading ?
                                    <div>
                                        <Spin /> <span> Uploading...</span>
                                    </div>
                                    : */}
                            <span>Upload Excel File Only</span>
                            {/* } */}
                        </p>
                    </Dragger>
                    <NJVInput type="primary"
                        bgcolor={Theme.colors.secondary} placeholder="Description" style={{ marginTop: 15 }} />
                    <Button type="primary" style={{ marginTop: 15 }} onClick={() => this.handleFileUpload()}>Upload</Button>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <TitleLevel3 label={"Invoice"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={() => this.setState({ openUploadExcelModal: true })}>Convert</Button>
                    </Col>
                    <Col span={24}>
                        <Form onFinish={this.onSearch}>
                            <Row gutter={[16, 16]}>
                                <Col span={4}>
                                    <Form.Item name={'invoiceId'}>
                                        <NJVInput
                                            bgcolor={Theme.colors.secondary}
                                            type="primary"
                                            placeholder="Search by Invoice ID"
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type='primary'
                                            size="large"
                                            style={{ width: '100%' }}
                                            >Search</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* {
                    isDataFetching ?
                        <Col span={24}>
                            <Skeleton active />
                        </Col>
                        : */}
                    {
                        shipperView ?
                            <Col span={24}>
                                <Table
                                    columns={shipperColumns}
                                    dataSource={shipperInvoicedata}
                                    pagination={false}
                                />
                            </Col> :
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource={invoiceData}
                                    pagination={false}
                                />
                            </Col>
                    }
                    {/* } */}
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(InvoicePage)
