import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Col, Form, Input, Row, TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DynamicEnumSelect from '../../components/fetch-enum'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

class BannerUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props?.location?.state?.updateBanner || null,
            isLoading: false,
            bannerTypes: null
        }
    }

    updateBanner = async (values) => {

        this.setState({
            isLoading: true
        })
        const requestData = {
            'id': this.state.data.id,
            'bannerName': values.name,
            'type': values.type,
            'startTime': dayjs(values.time[0]).format('HH:mm'),
            'endTime': dayjs(values.time[1]).format('HH:mm')
        }
        await ApiHandler({ url: Api.banner, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(() => {
                this.props.navigate(CustomPath.banner)
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const { data } = this.state;

        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.banner}>Loyalty Banner</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', paddingInline: 20, paddingBlock: 10 }}
                            bordered={false}
                            title={"Loyalty Banner Update"}
                            extra={<Button type="primary" onClick={() => this.props.navigate(CustomPath.banner)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                initialValues={{ name: data?.bannerName, type: data?.type, time: [dayjs(data?.startTime, 'HH:mm'), dayjs(data?.endTime, 'HH:mm')], status: data?.status }}
                                onFinish={this.updateBanner}>

                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Form.Item name="name" label={"Banner Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter Banner name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="type" label={"Banner Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <DynamicEnumSelect enumtype="BANNER_TYPE" size='large' placeholder={"Select Type"} width="100%" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="time" label={"Start Time / End Time"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TimePicker.RangePicker format="hh:mm" size="large" style={{ width: '100%' }}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(BannerUpdate);