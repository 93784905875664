import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBuCfiaj1gpo-y7H_cK8BSRs7mXpuBeCV8",
    authDomain: "nvm-notification-engine.firebaseapp.com",
    projectId: "nvm-notification-engine",
    storageBucket: "nvm-notification-engine.firebasestorage.app",
    messagingSenderId: "110196093923",
    appId: "1:110196093923:web:aa7e4ed244ee9a47b026d6",
    measurementId: "G-RVQ17NEYZ1"
}

const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app);