import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Col, Flex, Form, Input, Row, Skeleton, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DynamicEnumSelect from '../../components/fetch-enum'
import Theme, { Colors } from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

const bannerTypeMap = {
    'LOYALTY_SHOP_DASHBOARD': 'Loyalty Shop Dashboard',
    'SHIPPER_DASHBOARD': 'Shipper Dashboard',
    'CUSTOMER_DASHBOARD': 'Customer Dashboard',
}

class BannerCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            bannerTypes: null
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({
            isDataFetching: true
        })
        ApiHandler({ url: Api.banner_list, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    createBanner = async (values) => {
        this.setState({
            isLoading: true
        })
        const requestData = {
            'bannerName': values.name,
            'type': values.type,
            'startTime': dayjs(values.time[0]).format('HH:mm'),
            'endTime': dayjs(values.time[1]).format('HH:mm'),
            'status': values.status === true ? 'ACTIVE' : 'INACTIVE'
        }
        await ApiHandler({ url: Api.banner, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(() => {
                this.props.navigate(CustomPath.banner)
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const { data, isDataFetching } = this.state;

        const renderTimeInterval = (data, bannerType) => {
            return data.filter(item => item.type === bannerType).map((item, index) => (
                <span key={index} style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    border: '1px solid #ff0000',
                    width: 'fit-content',
                    padding: '10px 20px',
                    borderRadius: 10,
                    marginRight: 10,
                    marginBottom: 5
                }}>
                    <span>{`${item?.startTime.slice(':', 5)} to ${item?.endTime.slice(':', 5)}`}</span>
                </span>
            ))
        }

        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.banner}>Loyalty Banner</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        {
                            isDataFetching ? <Skeleton active /> :
                                <Flex vertical wrap="wrap" gap={15} style={{ marginBottom: 20 }}>
                                    {
                                        Object.keys(bannerTypeMap).map((bannerType) => {
                                            const hasInterval = data?.some(item => item.type === bannerType)
                                            return hasInterval ? (
                                                <React.Fragment key={bannerType}>
                                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                                        Unavailable <span style={{ color: Colors.primary }}>{bannerTypeMap[bannerType]}</span> Time Interval
                                                    </Typography.Title>
                                                    <Flex wrap="wrap">
                                                        {
                                                            renderTimeInterval(data, bannerType)
                                                        }
                                                    </Flex>
                                                </React.Fragment>
                                            ) : null
                                        })
                                    }
                                </Flex>
                        }
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', paddingInline: 20, paddingBlock: 10 }}
                            bordered={false}
                            title={"Loyalty Banner Form"}
                            extra={<Button type="primary" onClick={() => this.props.navigate(CustomPath.banner)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                initialValues={{ status: true }}
                                onFinish={this.createBanner}>

                                <Row gutter={[24, 24]}>
                                    <Col span={8}>
                                        <Form.Item name="name" label={"Banner Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter Banner name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="type" label={"Banner Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <DynamicEnumSelect enumtype='BANNER_TYPE' size='large' placeholder={"Select Type"} width="100%" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="time" label={"Start Time / End Time"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TimePicker.RangePicker format="hh:mm" size="large" style={{ width: '100%' }}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(BannerCreate);