import { Button, Col, DatePicker, Form, Input, Row, Skeleton, Table } from 'antd';
import React from 'react';
import { Constant } from '../core/constant';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { ApiHandler, downloadExcel } from '../network/network-manager';
import FetchSearchUrl from './fetch-searchUrl';
import SearchPagination from './searchPagination';
import SearchFilter from './searchFilter';
import { NJVSelect } from './core-component';
import Theme from './theme';
import dayjs from 'dayjs';
import { formatDateDigit } from './format';
import DynamicEnumSelect from './fetch-enum';
const { RangePicker } = DatePicker

export default class NJVTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            isDataFetching: false,
            filterData: null
        }
        this.formRef = React.createRef()
    }

    componentDidMount() {
        const { urlPage, filterData } = FetchSearchUrl({ ...this.props.defaultFilter })
        let formattedFilterData = { ...filterData };

        if (filterData['fromDate-toDate']) {
            formattedFilterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
        }
        if (filterData?.fromDate && filterData?.toDate) {
            formattedFilterData.fromDate = dayjs(filterData?.fromDate)
            formattedFilterData.toDate = dayjs(filterData?.toDate)
        }

        if (urlPage) {
            this.setState({
                filterData: formattedFilterData
            }, () => this.fetchData(urlPage))
        }

        this?.props?.handleRefetch?.(this.fetchData)
        this?.props?.handleExportExcel?.(this.downloadExcel)
        this.formRef?.current?.setFieldsValue({ ...formattedFilterData })
    }

    fetchData = async (pageNumber, values) => {
        const { page, pageSize, filterData } = this.state

        const formattedFilterData = this.formattedDateRange(filterData, filterData?.['fromDate-toDate'])

        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize,
            ...formattedFilterData,
            ...values
        }

        this.setState({
            isDataFetching: true
        })
        await ApiHandler({ url: this.props.apiUrl, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.specificId, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    downloadExcel = async () => {
        let { page, pageSize, filterData } = this.state
        if (this.props.onDownloadStatusChange) {
            this.props.onDownloadStatusChange(true);
        }

        const formattedFilterData = this.formattedDateRange(filterData, filterData?.['fromDate-toDate'])

        let params = {
            page: page - 1,
            size: pageSize,
            ...formattedFilterData
        }
        await downloadExcel(this.props.excelExport.url, params, this.props.excelExport.name)
            .then(() => { })
            .catch(() => {
            }).finally(() => {
                if (this.props.onDownloadStatusChange) {
                    this.props.onDownloadStatusChange(false);
                }
            })
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber)
    };

    onFinish = (values) => {

        const formattedFilterData = this.formattedDateRange(values, values?.['fromDate-toDate'])

        this.setState({
            filterData: formattedFilterData
        })

        const newUrl = SearchFilter(formattedFilterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1, formattedFilterData)
    }

    formattedDateRange = (values, condition) => {
        const formattedFilterData = { ...values }
        if (condition) {
            formattedFilterData['fromDate-toDate'] = `${formatDateDigit(values['fromDate-toDate'][0])}&${formatDateDigit(values['fromDate-toDate'][1])}`;
            formattedFilterData.fromDate = `${formatDateDigit(values['fromDate-toDate'][0])}`
            formattedFilterData.toDate = `${formatDateDigit(values['fromDate-toDate'][1])}`
        } else {
            formattedFilterData.fromDate = null
            formattedFilterData.toDate = null
        }

        return formattedFilterData;
    }

    render() {
        const { data, page, pageSize, totalPagination, isDataFetching } = this.state;

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            ...this.props.columns
        ]

        const getRowClassName = (_, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const paramCompo = (param) => {

            switch (param.type) {
                case 'Input':
                    return <Input
                        style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                        placeholder={param.name}
                        autoComplete='off'
                    />;
                case 'Select':
                    return <NJVSelect
                        size="large"
                        allowClear
                        placeholder={param.name}
                        bgcolor={Theme.colors.input_bg_color}
                        options={
                            param.selectCollection && param.selectCollection?.map((type, index) => {
                                return {
                                    key: index,
                                    value: type.value,
                                    label: type.label
                                }
                            })
                        } />
                        ;
                case 'RangePicker':
                    return <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                        allowClear
                    />;
                case 'DynamicSelect':
                    return <DynamicEnumSelect
                        allowClear
                        enumtype={param.enumtype}
                        size="large"
                        className="custom-selector-gray"
                        style={{ width: '100%' }}
                        placeholder="Select Shop Type" />;
                default:
                    break;
            }
        }

        return (
            <>
                <Form ref={this.formRef} onFinish={this.onFinish}>
                    <Row gutter={[16, 16]}>
                        {
                            this.props.filters?.map((param) => (
                                <Col span={6}>
                                    <Form.Item name={param.key}>
                                        {
                                            paramCompo(param)
                                        }
                                    </Form.Item>
                                </Col>
                            ))
                        }
                        {
                            this.props.filters && <Col span={6}>
                                <Form.Item>
                                    <Button htmlType='submit' type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </Form>
                {
                    isDataFetching ?
                        <Skeleton active />
                        :
                        <Table
                            rowKey="id"
                            pagination={{
                                position: 'top' | 'bottom',
                                total: totalPagination,
                                current: page,
                                onChange: this.handlePaginationChange,
                                defaultPageSize: pageSize,
                                showSizeChanger: false
                            }}
                            columns={columns}
                            dataSource={data}
                            rowClassName={getRowClassName}
                        />
                }
            </>

        )
    }
}