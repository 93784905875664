import { EditOutlined } from "@ant-design/icons"
import { compose } from '@reduxjs/toolkit'
import { Button, Col, Row, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NJVAddButton } from '../../components/core-component'
import { TitleLevel3 } from '../../components/general-component'
import NJVTable from '../../components/njv-table'
import Api from '../../network/api'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import { DEPARTMENT_COLLECTION, FORM_ITEM } from "../../core/constant"

class JobPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: false,
            currentRecord: null
        }
    }

    render() {
        const currentSearchUrl = window.location.search;
        const columns = [
            {
                title: 'Position Name',
                key: 'position',
                dataIndex: 'position'
            },
            {
                title: 'Created Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                render: (value) => dayjs(value).format('YYYY-MM-DD hh:mm A')
            },
            {
                title: 'Total Candidate',
                key: 'totalCandidate',
                dataIndex: 'totalCandidate',
                align: 'center'
            },
            {
                title: 'Applied Candidates',
                key: 'view',
                width: 300,
                align: 'center',
                render: (record) => <Link to={`${CustomPath.job_post}/${record.id}`} state={{ route: `${CustomPath.shipper_approval}${currentSearchUrl}`, data: record }}><Button size={'middle'} type="dashed" shape="rounded" style={{ paddingLeft: 10, paddingRight: 10 }}>View All Candidates</Button></Link>
            },
            {
                title: 'Status',
                key: 'statusFlag',
                dataIndex: 'statusFlag',
                align: 'center',
                render: (statusFlag) => (
                    <>
                        {
                            (
                                statusFlag === "ACTIVE" ? <Tag color="#87d068">{statusFlag}</Tag> : <Tag color="#cd201f">{statusFlag}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                width: 100,
                align: 'center',
                render: (record) => <Link to={`${CustomPath.job_post_update}/${record.id}`} state={{ route: `${CustomPath.shipper}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle" style={{ marginBottom: 5 }}><EditOutlined /></Button></Link>
            }
        ]

        const filterColumn = [
            {
                key: 'Position',
                name: 'Enter Position',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'department',
                name: 'Select Department',
                type: FORM_ITEM.SELECT,
                selectCollection: DEPARTMENT_COLLECTION
            },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Ninja Career"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.job_post_create}><NJVAddButton /></Link>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.job_post_filter}
                        filters={filterColumn}
                    />
                </Col>
            </Row>
        )
    }
}


export default compose(withRouter)(JobPage)