import { EditOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Row, Tag } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NJVAddButton } from '../../../components/core-component';
import { TitleLevel3 } from '../../../components/general-component';
import NJVTable from '../../../components/njv-table';
import Api from '../../../network/api';
import withRouter from '../../../network/with-router';
import CustomPath from '../../../routes/custom-path';
import { FORM_ITEM } from "../../../core/constant";

class StorePage extends Component {

    render() {

        const columns = [
            {
                title: 'Store Name',
                key: 'storeName',
                dataIndex: 'storeName'
            },
            {
                title: 'Special Code',
                key: 'specialCode',
                dataIndex: 'specialCode'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { statusFlag }) => (
                    <>
                        {
                            (
                                statusFlag === "ACTIVE" ? <Tag color="#87d068">{statusFlag}</Tag> : <Tag color="#cd201f">{statusFlag}</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (store) => (
                    <Link to={`${CustomPath.store_update}/${store.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        const filterColumn = [
            {
                key: 'name',
                name: 'Search by name',
                type: FORM_ITEM.INPUT,
            },
            {
                key: 'specialCode',
                name: 'Search by special code',
                type: FORM_ITEM.INPUT,
            },
            {
                key: 'Select store type',
                name: 'type',
                type: FORM_ITEM.SELECT,
                selectCollection: [
                    {
                        label: 'Restaurant',
                        value: 'RESTAURANT',
                    },
                ]
            },

        ]


        return (
            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Store"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.store_create}><NJVAddButton /></Link>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.store_filter}
                        filters={filterColumn}
                    />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(StorePage)