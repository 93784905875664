import { Divider, Image } from "antd"
import dayjs from 'dayjs'
import { SvgParcelCancelled, SvgParcelComplete, SvgParcelOnOperation, SvgParcelOnVehicle, SvgParcelPickUp, SvgPendingPickup, SvgWarningFilled } from "../../components/custom-svg"
import Theme, { Colors } from "../../components/theme"

const dividerStyle = {
    width: 1.5,
    height: '100%', position: 'absolute',
    top: 0, left: 12.25, bottom: 0, borderRadius: 10,
    backgroundImage: 'repeating-linear-gradient(180deg, gray, gray 12px, transparent 12px, transparent 16px)',
    backgroundPosition: 'right top',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '2px 100%',
    marginLeft: 9.5
}

const stepCirclestyle = {
    width: 45, height: 45, borderRadius: 30, backgroundColor: 'lightgray', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',
}

const GetSvgIconBaseOnStatus = ({ status = 'PENDING' }) => {
    if (status === 'DELIVERED') {
        return <SvgParcelComplete width={25} height={25} color='green' />
    } else if (status === 'DELIVERY_EXCEPTION' || status === 'PICK_UP_EXCEPTION') {
        return <SvgWarningFilled width={25} height={25} color='chocolate' />
    } else if (status === 'ON_VEHICLE_FOR_DELIVERY') {
        return <SvgParcelOnVehicle width={25} height={25} color={Theme.colors.default} />
    } else if (status === 'CANCELLED') {
        return <SvgParcelCancelled width={30} height={30} color={Colors.red} />
    } else if (status === 'PENDING_PICK_UP') {
        return <SvgPendingPickup width={25} height={25} color='gray' />
    } else if (status === 'ARRIVED_AT_ORIGIN_HUB' || status === 'ARRIVED_AT_SORTING_HUB' || status === 'ARRIVED_AT_TRANSIT_HUB' || status === 'ARRIVED_AT_DESTINATION_HUB' || status === 'IN_TRANSIT_TO_NEXT_SORTING_HUB') {
        return <SvgParcelOnOperation width={23} height={23} color={Theme.colors.default} />
    } else if (status === 'PICKED_UP') {
        return <SvgParcelPickUp width={25} height={25} color='green' />
    }
}
export const ParcelEventSteps = ({ events = [] }) => {
    return (
        <>
            {
                events && events.map((event, index) => (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            {
                                index !== events.length - 1 && <Divider type="vertical" style={dividerStyle} />
                            }
                            <div style={{ width: '100%', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', }}>
                                    <div>
                                        <div style={stepCirclestyle}>
                                            <GetSvgIconBaseOnStatus status={event.orderStatus} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: 'space-between', width: '100%' }}>
                                        <div style={{ fontWeight: '500', fontSize: 16 }}>{event.status}</div>
                                        <div style={{ fontWeight: '500' }}>{event.timestamp && dayjs(event.timestamp, 'YYYY-MM-DD HH:mm:ss')?.format('YYYY-MM-DD hh:mm A')}</div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 55, marginBottom: 15, marginTop: 5 }}>
                                    {
                                        event.deliveryException ?
                                            <>
                                                {
                                                    event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                                                            {
                                                                event.deliveryException.proof.image_uris.map(image =>
                                                                    <div style={{ marginRight: 5 }}>
                                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    event.deliveryException.failure_reason ?
                                                        <div style={{ color: 'red', marginTop: 5, fontSize: 15, fontWeight: '500' }}>Fail Reason <br /> {event.deliveryException.failure_reason}</div>
                                                        :
                                                        <></>
                                                }
                                            </>

                                            :
                                            <></>
                                    }
                                    {
                                        event.deliveryInformation ?
                                            <>
                                                {
                                                    event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                event.deliveryInformation.proof.image_uris.map(image =>
                                                                    <div style={{ marginLeft: 5 }}>
                                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        event.pickupException ?
                                            <>
                                                {
                                                    event.pickupException.failure_reason ?
                                                        <div style={{ color: 'red' }}>Fail Reason : {event.pickupException.failure_reason}</div>
                                                        :
                                                        <></>
                                                }
                                            </>

                                            :
                                            <></>
                                    }
                                    {
                                        event.pickedUpInformation ?
                                            <>
                                                {
                                                    event.pickedUpInformation.proof && event.pickedUpInformation.proof.image_uris ?
                                                        <div style={{ display: 'flex' }}>
                                                            {
                                                                event.pickedUpInformation.proof.image_uris.map(image =>
                                                                    <div style={{ marginLeft: 5 }}>
                                                                        <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

        </>
    )
}