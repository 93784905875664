import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { TitleLevel3 } from '../../components/general-component';
import NJVTable from "../../components/njv-table";
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import { FORM_ITEM } from '../../core/constant';

export default class ShipperLoyaltyPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSyncing: false,
            currentId: null,
        }
    }

    handleSync = async (value) => {
        this.setState({
            isSyncing: true
        })
        const requestParams = {
            'shipperIds': value
        }

        await ApiHandler({ url: Api.loyalty_sync, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isSyncing: false
                })
            })
    }

    render() {
        const { isSyncing, currentId } = this.state

        const columns = [
            {
                title: 'Business Name',
                key: 'businessName',
                dataIndex: 'businessName'
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Current Month’s Level',
                key: 'currentMonthLevel',
                dataIndex: 'currentMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month’s Level',
                key: 'previousMonthLevel',
                dataIndex: 'previousMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Current Month Point',
                key: 'currentMonthPoint',
                dataIndex: 'currentMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month Point',
                key: 'previousMonthPoint',
                dataIndex: 'previousMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Sync',
                key: 'action',
                align: 'center',
                render: (value, item) => <Button type="primary" style={{ borderRadius: 10 }}
                    loading={currentId === item.userId ? isSyncing : false}
                    onClick={() => {
                        this.handleSync(item?.userId)
                        this.setState({
                            currentId: item?.userId
                        })
                    }}>Sync</Button>
            }
        ]

        const filterColumn = [
            {
                key: 'businessName',
                name: 'Search by Business Name',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'phoneNumber',
                name: 'Search by Phone Number',
                type: FORM_ITEM.INPUT
            },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Loyalty Point"} />
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.shipper_loyalty}
                        filters={filterColumn}
                    />
                </Col>
            </Row>
        )
    }
}
